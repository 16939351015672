import React, {useEffect, useState, useContext, SyntheticEvent} from 'react';
import './Proposte.css';
import { useNavigate, useParams } from 'react-router-dom';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import IconButton from '@mui/material/IconButton';
import { AddIcon, CheckIcon, EditIcon, PercentageIcon } from '../../../../assets/icons/icons';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import config from '../../../../config.ts';
import { AuthContext } from '../../../../contexts/authContext.tsx';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { formatNumbersIt, getFormatDate, getFormatDateFromDate } from '../../../../utils/CommonUtilities.ts';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress.tsx';
import { 
    DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid-premium';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteClientsAgentsObject } from '../../../../utils/ConfigInterfaces.ts';
import { LocalizationProvider }             from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }                    from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }                       from '@mui/x-date-pickers/DatePicker';
import moment                               from 'moment';
import 'moment/locale/it';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

LicenseInfo.setLicenseKey(
    'dd496cfc4b1b33990ea31063647a918dTz03ODE5MixFPTE3MzA5ODc5NDYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

const ActionHeader:any = () => {
    const navigate = useNavigate();
    return <IconButton onClick={() => navigate('/proposta/new')}><AddIcon addClass='font-16'/></IconButton>
}

const ActionCell:any = (params:any) => {
    const navigate = useNavigate();
    return !params.id ? null : <IconButton onClick={()=>{navigate(`/proposta/${params.row.KPROPOSTA}`)}}><EditIcon addClass='font-16'/></IconButton>
}

const FlaggedCell:any = (params:any) => {
    if(!params.id){
        return null;
    }else if (params.field==='ATTIVO'){
        return params.row.DATA_CANC ? '' : <CheckIcon addClass='font-16 textGreen'/>;
    }else{
        return params.value === 'N' ? '' : <CheckIcon addClass='font-16 textGreen'/>;
    }
}

const columns:GridColDef[] = [
    { field: 'KPROPOSTA', headerClassName: 'header-cell propostaCod', headerName: "Id", type: 'text', width: 75, editable: false, disableColumnMenu: true, align: 'center', headerAlign: 'center'},
    { field: 'DAGENZIA', headerClassName: 'header-cell agenziaDesc', headerName: "Agenzia", type: 'text', width: 100, editable: false},
    { field: 'DCLIENTE', headerClassName: 'header-cell clienteDesc', headerName: "Cliente", type: 'text', width: 300, editable: false},
    { field: 'DTALENT', headerClassName: 'header-cell talentDesc', headerName: "Talent", type: 'text', width: 155, editable: false},
    { field: 'USER_INS', headerClassName: 'header-cell userInserimento', headerName: "Inserito da", type: 'text', width: 100, editable: false},
    { field: 'BEGIN_DATE', headerClassName: 'header-cell beginDate', headerName: "Data Inizio", type: 'text', width: 100, editable: false, valueFormatter: (params) => getFormatDate(params.value)},
    { field: 'END_DATE', headerClassName: 'header-cell endDate', headerName: "Data Fine", type: 'text', width: 100, editable: false, valueFormatter: (params) => getFormatDate(params.value)},
    { field: 'TOT_RICAVI', headerClassName: 'header-cell totRicavi', headerName: "Totale Ricavi", type: 'text', width: 135, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
    { field: 'TOTALE_FEE', headerClassName: 'header-cell totFee', headerName: "Totale Fee", type: 'text', width: 135, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
    { field: 'DPROPOSTA', headerClassName: 'header-cell propostaDesc', headerName: "Proposta", type: 'text', width: 300, editable: false},
    { field: 'DDIPARTIMENTO', headerClassName: 'header-cell dipartimentoDesc', headerName: "Dipartimento", type: 'text', width: 100, editable: false},
    { field: 'DREF_COMM', headerClassName: 'header-cell referenteCommercialeDesc', headerName: "Referente Commerciale", type: 'text', width: 100, editable: false},
    { field: 'DREF_PROG', headerClassName: 'header-cell referenteProgettoDesc', headerName: "Referente Progetto Esterno", type: 'text', width: 100, editable: false},
    { field: 'DINTEST_FATT', headerClassName: 'header-cell intestatarioFatturaDesc', headerName: "Intestatario Fattura", type: 'text', width: 100, editable: false},
    { field: 'DSTATO', headerClassName: 'header-cell statusProposta', headerName: "Status", type: 'text', width: 100, editable: false},
    { field: 'ACTION', headerClassName: 'header-cell action', headerName: "", type: 'text', width: 60, editable: false, sortable: false, headerAlign: 'center', align: 'center', filterable: false,
    renderCell: (params:any) => ActionCell(params),
    renderHeader: () => ActionHeader(),
    disableColumnMenu: true,
    disableExport: true}
    /* { field: 'DTALENT_MANAGER', headerClassName: 'header-cell talentManagerDesc', headerName: "Talent Manager", type: 'text', width: 100, editable: false}, */
    /* { field: 'FLAG_BUDGET', headerClassName: 'header-cell flagBudget', headerName: "Budget", type: 'text', width: 100, editable: false,
        renderCell: (params:any) => FlaggedCell(params)}, */
    /* { field: 'FLAG_FATT_DIR_TALENT', headerClassName: 'header-cell flagFattDirettaTalent', headerName: "Fatturazione diretta talent", type: 'text', width: 100, editable: false,
        renderCell: (params:any) => FlaggedCell(params)}, */
];  

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function ListaProposte(props:any) {

    const { changeListaPropostaPageName } = useContext(AppAuthGlobalVariables);
    const urlParams = useParams();
    const auth = useContext(AuthContext);

    const [ rows     , setRows     ] = useState<GridRowModel[]>([]);
    const [ loading  , setLoading  ] = useState(true)
    const [ onReload , setOnReload ] = useState<any>(null)

    //const [ anno, setAnno ] = useState<any>(new Date().getFullYear().toString());
    /* const [ kAgenzia, setKAgenzia ] = useState<any>('');
    const [ kCliente, setKCliente ] = useState<any>('');
    const [ kReferenteCommerciale, setKReferenteCommerciale ] = useState<any>('');
    const [ kTalent, setKTalent ] = useState<any>(''); */
    //const [ oPeriodo, set_oPeriodo ] = useState<any>({ sDataInizio: null, sDataFine: null });

    /* const [ dAgenzia, setDAgenzia ] = useState<any>('');
    const [ dCliente, setDCliente ] = useState<any>('');
    const [ dReferenteCommerciale, setDReferenteCommerciale ] = useState<any>('');
    const [ dTalent, setDTalent ] = useState<any>(''); */

    const [ agenzie, setAgenzie ] = useState<any>([]);
    const [ clienti, setClienti ] = useState<any>([]);
    const [ referentiCommerciali, setReferentiCommerciali ] = useState<any>([]);
    const [ talents, setTalents ] = useState<any>([]);

    const [openExtraFiltersDialog, setOpenExtraFiltersDialog] = useState<any>(false);

    useEffect(() => {
        changeListaPropostaPageName?.(urlParams.status);
        async function init(){
            //getProposte();
            async function clienti() { await getAnagrafica({ url: 'clienti' })() };
            async function agenzie() { await getAnagrafica({ url: 'agenzie' })() };
            async function refCommerciali() { await getAnagrafica({ url: 'users' })() };
            async function talents() { await getAnagrafica({ url: 'talents' })() };

            await Promise.all([
                clienti(),
                agenzie(),
                refCommerciali(),
                talents(),
                getProposte()
            ]).then(values => {
                setLoading(false);
            }).catch((e:any) => {
                if(e.response.status===500){
                    NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
                }else if(e.response.status===401){
                    NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                    props.logOut();
                }else{
                    NotificationManager.error('Errore di Rete', 'Errore', 10000);
                }
                setLoading(false);
            })
        }
        init();
    },[urlParams.status]);

    useEffect(() => {
        if (onReload !== null) setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[rows])

    useEffect(() => {
        if(!loading){
            if(props.oPeriodo.sDataInizio !== 'Invalid date' && props.oPeriodo.sDataFine !== 'Invalid date'){
                if(props.anno.length === 4){
                    getProposte();
                }
            }
        }
    },[props.kAgenzia, props.kCliente, props.kReferenteCommerciale, props.oPeriodo, props.kTalent, props.anno, props.idPropostaFiltro])

    const getAnagrafica = ({
		url = ''
		, method = 'get'
		, baseURL = config.API_URL
		, headers = { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` }
		, params = {}
	}) => {
		return async () => {
			try {
				const axiosParams = { url, method, baseURL, headers, params };
				const response = await axios(axiosParams);
				if (response.status === 200) {
					let data = response.data || [];

					if(url === 'clienti'){
						setClienti(data);
					}else if(url === 'agenzie'){
						setAgenzie(data);
					}else if(url === 'users'){
						setReferentiCommerciali(response.data.filter((o: any) => o.FLAG_REF_COMM === 'Y'));
					}else if(url === 'talents'){
                        setTalents(data.sort((a:any, b:any) => a.TIPO > b.TIPO ? -1 : a.TIPO < b.TIPO ? 1 : 0 ));
                    }
				} else {
					throw new Error('Fallito caricamento');
				}

			} catch (e:any) {
				if(e.response.status===500){
					NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
				}else if(e.response.status===401){
					NotificationManager.error('Non Autorizzato', 'Errore', 10000);
					props.logOut();
				}else{
					NotificationManager.error('Errore di Rete', 'Errore', 10000);
				}
				return [];
			}
		}
	}

    const getProposte = async() => {
        setOnReload(!onReload);
        setLoading(true);
        try{
            let axiosParams = {
                url: 'proposte',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                    anno: props.anno,
                    kStato: urlParams.status === '04' ? '04,06' : urlParams.status,
                    dAgenzia: props.dAgenzia,
                    dCliente: props.dCliente,
                    dReferenteCommerciale: props.dReferenteCommerciale,
                    beginDate: props.oPeriodo.sDataInizio,
                    endDate: props.oPeriodo.sDataFine,
                    dTalent: props.dTalent,
                    kProposta: props.idPropostaFiltro
                }
            }

            let response = await axios(axiosParams);

            if(response.status === 200){
                let proposals = [...response.data];
                setRows(proposals);

                if(proposals.length){
                    props.setAllPropCounter(proposals[0].N_TOT);
                    props.setLeadsPropCounter(proposals[0].N_LEAD);
                    props.setConfermatePropCounter(proposals[0].N_CONF);
                    props.setContractsPropCounter(proposals[0].N_CONTR_TOT);
                    props.setContractsInModPropCounter(proposals[0].N_CONTR_MOD);
                    props.setClosePropCounter(proposals[0].N_CHIUSE);
                    props.setRejectedPropCounter(proposals[0].N_RIF);
                    props.setDeletedPropCounter(proposals[0].N_CANC);
                }
            }
            //setLoading(false);
        }catch (e:any){
            if(e.response && e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response && e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        } 
    }

    const handleCloseExtraFiltersDialog = () => {
        setOpenExtraFiltersDialog(false);
    }

    const handleOpenExtraFiltersDialog = () => {
        setOpenExtraFiltersDialog(true);
    }

    const handleApplyFilters = () => {

    }

    return (
        <div className='component-container proposte'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <div className='filters-row'>
                    <div className='wrapped-filters'>
                        <TextField 
                            id="anno" 
                            label="Anno" 
                            variant="standard"
                            value={props.anno}
                            onChange={(event) => props.setAnno(event.target.value)} 
                            inputProps={{maxLength: 4}}
                            error={props.anno.length!==4}
                            sx={{maxWidth:'80px'}}
                        />
                        <Autocomplete
                            disablePortal
                            id                   = "agenzia"
                            options              = { agenzie }
                            value                = { agenzie.find( (o:any) => o.id === props.kAgenzia ) || ''}
                            multiple             = { false }
                            onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.setKAgenzia( newValue?.id ); props.setDAgenzia( newValue?.label ) } }
                            sx                   = { { width: 300 } }
                            renderInput          = { (params) => <TextField {...params} label="Agenzia" variant="standard"/> }
                        />
                        <Autocomplete
                            disablePortal
                            id                   = "cliente"
                            options              = { clienti }
                            value                = { clienti.find( (o:any) => o.id === props.kCliente ) || ''}
                            multiple             = { false }
                            onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.setKCliente( newValue?.id ); props.setDCliente( newValue?.label ) } }
                            sx                   = { { width: 300 } }
                            renderInput          = { (params) => <TextField {...params} label="Cliente" variant="standard"/> }
                        />
                        <Autocomplete
                            disablePortal
                            id                   = "refComm"
                            options              = { referentiCommerciali }
                            value                = { referentiCommerciali.find( (o:any) => o.id === props.kReferenteCommerciale ) || ''}
                            multiple             = { false }
                            onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.setKReferenteCommerciale( newValue?.id ); props.setDReferenteCommerciale( newValue?.label ) } }
                            sx                   = { { width: 300 } }
                            renderInput          = { (params) => <TextField {...params} label="Referente Commerciale" variant="standard"/> }
                        />
                        <Autocomplete
                            disablePortal
                            id                   = "talent"
                            options              = { talents }
                            value                = { talents.find( (o:any) => o.id === props.kTalent ) || ''}
                            multiple             = { false }
                            groupBy              = { (option: any) => {return option.TIPO}}
                            onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.setKTalent( newValue?.id ); props.setDTalent( newValue?.label ) } }
                            sx                   = { { width: 300 } }
                            renderInput          = { (params) => <TextField {...params} label="Talent" variant="standard"/> }
                        />
                        <TextField 
                            id="codice" 
                            label="Id" 
                            variant="standard"
                            value={props.idPropostaFiltro}
                            onChange={(event) => props.setIdPropostaFiltro(event.target.value)} 
                            sx={{maxWidth:'80px'}}
                        />
                    </div>

                    <IconButton onClick={handleOpenExtraFiltersDialog}><i className="fa-regular fa-bars" style={{color: props.oPeriodo?.sDataInizio || props.oPeriodo.sDataFine ? '#1976d2' : ''}}></i></IconButton>
                </div>
                <div className='lista-proposte-container'>
                    <DataGridPremium
                        className='dataGrid'
                        slots={{
                            noRowsOverlay: NoRowsDataGridComponent,
                            toolbar: CustomToolbar
                        }}
                        initialState={{ pinnedColumns: { left: ['ACTION'], right: [] } }}
                        rows={rows}
                        columns={columns}
                        rowHeight={30}
                        hideFooter={true}
                        columnVisibilityModel={{"TOTALE_FEE": !!props.dTalent}}
                        isCellEditable={(params) => params.id!==0}
                        disableColumnMenu={false}
                        disableColumnReorder={true}
                        disableAggregation={true}
                        disableColumnFilter={true}
                        getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
                        pinnedRows={{bottom: [], top: [rows.length > 0 ? rows[0] : {id:0}]}}
                    />
                </div>
            </div>
            <Dialog
                open={openExtraFiltersDialog}
                onClose={handleCloseExtraFiltersDialog}
                aria-labelledby="dialog-extra-filters-title"
                aria-describedby="dialog-extra-filters-description"
            >
                <DialogTitle id="dialog-extra-filters-title">
                    Filtri Aggiuntivi
                </DialogTitle>
                <DialogContent className='dialog-extra-filters-body'>
                    <LocalizationProvider dateAdapter={AdapterMoment} key="periodo-proposta">
                        <DatePicker
                            label            = "Data Inizio Da"
                            format           = "DD/MM/YYYY"
                            views            = {['year', 'month', 'day']}
                            value            = { props.oPeriodo?.sDataInizio===null ? null : moment( props.oPeriodo?.sDataInizio || '', 'YYYYMMDD' ) }
                            onChange         = { ( value ) => {props.set_oPeriodo({ ...props.oPeriodo, sDataInizio: value===null ? null : moment(value).format('YYYYMMDD') })} }
                            slotProps        = {{ textField: { variant: 'outlined' } }}
                            className        = 'TextFieldDate'
                        />

                        <DatePicker
                            label            = "Data Inizio A"
                            format           = "DD/MM/YYYY"
                            views            = {['year', 'month', 'day']}
                            value            = { props.oPeriodo?.sDataFine===null ? null : moment( props.oPeriodo?.sDataFine || '', 'YYYYMMDD' ) }
                            onChange         = { ( value ) => props.set_oPeriodo({ ...props.oPeriodo, sDataFine: value===null ? null : moment(value).format('YYYYMMDD') }) }
                            slotProps        = {{ textField: { variant: 'outlined' } }}
                            className        = 'TextFieldDate'
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExtraFiltersDialog} autoFocus>Chiudi</Button>
                    {/* <Button onClick={handleApplyFilters} variant='contained'>
                        Applica
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ListaProposte