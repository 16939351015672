import React, {useState, useEffect, useContext} from 'react';
import './ReportClienti.css';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
    DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel,
	GRID_TREE_DATA_GROUPING_FIELD,
	DataGridPremiumProps,
	GridRenderCellParams,
	useGridApiContext,
	useGridSelector,
	gridFilteredDescendantCountLookupSelector,  GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-premium';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import axios from 'axios';
import config from '../../../../config';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { formatNumbersIt, getFormatDateFromDate } from '../../../../utils/CommonUtilities';
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { ArrowDown, ArrowRight } from '../../../../assets/icons/icons';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';

const columns:GridColDef[] = [
	{ field: 'CLIENTE_CHILD', headerClassName: 'header-cell clientChild', headerName: "Cliente Figlio", type: 'text', width: 200, editable: false},
	{ field: 'LEADS', headerClassName: 'header-cell leads', headerName: "Leads", type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'CONFERMATE', headerClassName: 'header-cell confermate', headerName: "Confermate", type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'RIFIUTATE', headerClassName: 'header-cell rifiutate', headerName: "Rifiutate", type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'ACTUAL', headerClassName: 'header-cell actual', headerName: "Actual", type: 'text', width: 150, editable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'TOTALE_PROPOSTE', headerClassName: 'header-cell totProposta', headerName: "Totale Proposte", type: 'text', width: 150, editable: false, description: 'Tutti gli stati', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'ACTUAL_FIN', headerClassName: 'header-cell actualFin', headerName: "ACT FIN", type: 'text', width: 150, editable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'PY_FIN', headerClassName: 'header-cell pyFin', headerName: "AP FIN", type: 'text', width: 150, editable: false, description: 'Anno Precedente: Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'DELTA_PY', headerClassName: 'header-cell deltaPy', headerName: "Δ Anno Prec.", type: 'text', width: 120, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
	{ field: 'GEN', headerClassName: 'header-cell gen', headerName: "Gennaio", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'FEB', headerClassName: 'header-cell feb', headerName: "Febbraio", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'MAR', headerClassName: 'header-cell mar', headerName: "Marzo", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'APR', headerClassName: 'header-cell apr', headerName: "Aprile", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'MAG', headerClassName: 'header-cell mag', headerName: "Maggio", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'GIU', headerClassName: 'header-cell giu', headerName: "Giugno", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'LUG', headerClassName: 'header-cell lug', headerName: "Luglio", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'AGO', headerClassName: 'header-cell ago', headerName: "Agosto", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'SETT', headerClassName: 'header-cell set', headerName: "Settembre", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'OTT', headerClassName: 'header-cell ott', headerName: "Ottobre", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'NOV', headerClassName: 'header-cell nov', headerName: "Novembre", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'DIC', headerClassName: 'header-cell dic', headerName: "Dicembre", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}}
];

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
	const { id, field, rowNode } = props;
	const apiRef = useGridApiContext();
	const filteredDescendantCountLookup = useGridSelector(
	  apiRef,
	  gridFilteredDescendantCountLookupSelector,
	);
	const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  
	const handleClick: ButtonProps['onClick'] = (event) => {
	  if (rowNode.type !== 'group') {
		return;
	  }
  
	  apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
	  apiRef.current.setCellFocus(id, field);
	  event.stopPropagation();
	};
	//filteredDescendantCount serve per stabilire se il padre ha dei figli
	//quando il un cliente è padre di sè stesso non mostro la casella del child perché ridondante
	if(props.row.id===0){
		return null;
	}else{
		return (
			<div style={{display: 'flex', alignItems: 'center', width: '300px'}}>
				
				{rowNode.type === 'group' ?
					<>
						{filteredDescendantCount > 1&&(
						<IconButton onClick={handleClick} sx={{ ml: rowNode.depth * 4 }}>
							{rowNode.childrenExpanded ? 
							<ArrowDown addClass='font-12'></ArrowDown>
								:
							<ArrowRight addClass='font-12'></ArrowRight>}
						</IconButton>)}
						{filteredDescendantCount > 1 ? `(${filteredDescendantCount})` : ''} {props.row.CLIENTE_PARENT}
					</>
					:
						<span/>
				}
			</div>
		);
	}
  }

const getTreeDataPath: any = (row:any) => row.hierarchy;

const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
	headerName: 'Cliente Padre',
	width: 300,
	renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};

function ReportClienti(props:any) {

	const auth = useContext(AuthContext);
	const {userEmail,setPageName} = useContext(AppAuthGlobalVariables)

	const [loading, setLoading] = useState(true);
	const [rows, setRows] = useState<GridRowModel[]>([]);
    const [talentManagers, setTalentManagers] = useState([]);
	const [referentiCommerciali, setReferentiCommerciali] = useState([]);
    const [anno, setAnno] = useState(new Date().getFullYear().toString());
    const [kTalentManager, setKTalentManager] = useState<any>('');
	const [kRefCommerciale, setKRefCommerciale] = useState<any>('');

	useEffect(() => {
		async function init() {
			if(talentManagers.length!==0){
				if(anno.length===4){
					await getReport();
				}
			}
		}
		init();
	},[anno, kTalentManager, kRefCommerciale, talentManagers]);
	
	useEffect(() => {
        async function init(){
            if(userEmail){
                await getUtenti();
				setPageName?.('Report - Clienti');
            }
        }
        init();
    },[userEmail]);

	const getUtenti = async() => {
        try {    
			let axiosParams = {
				url		: 'users',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
                let talentManagers = response.data.filter((tm:any) => tm.FLAG_TALENT_MANAGER === 'Y');
				let refCommerciali = response.data.filter((tm:any) => tm.FLAG_REF_COMM === 'Y');
				//chi vede sto report ? devo filtrare per l'utente loggato??
                if(localStorage.getItem('talent_manager')==='Y'){
                    let utenteCorrente = talentManagers.find((tm:any) => tm.EMAIL_ADDRESS === userEmail);
                    if(utenteCorrente){
                        setKTalentManager(utenteCorrente.id);
                    }
                }else{
                    //se l'utente non è talent manager seleziona tutti
                    setKTalentManager('');
                }
				if(localStorage.getItem('referente_commerciale')==='Y'){
                    let utenteCorrente = refCommerciali.find((tm:any) => tm.EMAIL_ADDRESS === userEmail);
                    if(utenteCorrente){
                        setKRefCommerciale(utenteCorrente.id);
                    }
                }else{
                    //se l'utente non è talent manager seleziona tutti
                    setKRefCommerciale('');
                }

				setTalentManagers(talentManagers);
				setReferentiCommerciali(refCommerciali);
			}

		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
    }

	const getReport = async() => {
		
		try {
            let axiosParams = {
                url		: 'report-clienti',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: { 
					anno,
					kTalentManager,
					kReferenteCommerciale: kRefCommerciale
				}
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data
				const data = rows.map((el:any,index:number) => {el.id = index; el.hierarchy = el.CLIENTE_CHILD ? [el.CLIENTE_PARENT, el.CLIENTE_CHILD] : [el.CLIENTE_PARENT]; return el})
	        	setRows([...data]);

				setLoading(false);
            }
        } catch (e:any) {
			setLoading(false);
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
	}
	
	return (
		<div className='component-container report-clienti'>
			{loading && (<CircleProgress/>)}
			<div className='component-card'>
				<div className='report-wrapper'>
					<div className='filters-section'>
						<TextField 
							id="anno" 
							label="Anno" 
							variant="standard"
							value={anno}
							onChange={(event) => setAnno(event.target.value)} 
							inputProps={{maxLength: 4}}
							sx={{maxWidth:'80px'}}
						/>
						<FormControl variant="standard" sx={{ m: 1, maxWidth: 300, width: '100%' }}>
							<InputLabel id="talentManagerLabel">Talent Manager</InputLabel>
							<Select
								labelId="talentManagerLabel"
								id="talentManager"
								value={kTalentManager}
								onChange={(event ) => setKTalentManager(event.target.value)}
								label="Talent Manager"
							>
								<MenuItem key={'0'} value={''}>Tutti</MenuItem>
								{talentManagers.map((tm:any) => <MenuItem key={tm.id} value={tm.id}>{tm.NOME} {tm.COGNOME}</MenuItem>)}
							</Select>
						</FormControl>
						<FormControl variant="standard" sx={{ m: 1, maxWidth: 300, width: '100%' }}>
							<InputLabel id="refCommercialeLabel">Referente Commerciale</InputLabel>
							<Select
								labelId="refCommercialeLabel"
								id="refCommerciale"
								value={kRefCommerciale}
								onChange={(event ) => setKRefCommerciale(event.target.value)}
								label="Referente Commerciale"
							>
								<MenuItem key={'0'} value={''}>Tutti</MenuItem>
								{referentiCommerciali.map((tm:any) => <MenuItem key={tm.id} value={tm.id}>{tm.NOME} {tm.COGNOME}</MenuItem>)}
							</Select>
						</FormControl>
					</div>
					<DataGridPremium
						className='dataGrid'
						slots={{
							noRowsOverlay: NoRowsDataGridComponent,
							toolbar: CustomToolbar,
						}}
						treeData
						getTreeDataPath={getTreeDataPath}
						rows={rows}
						columns={columns}
						rowHeight={30}
						hideFooter={true}
						isCellEditable={(params) => params.id!==0}
						disableColumnMenu={false}
						disableColumnReorder={true}
						disableAggregation={true}
						disableColumnFilter={true}
						disableRowGrouping={true}
						groupingColDef={groupingColDef}
						getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
						pinnedRows={{bottom: [], top: [rows.length > 0 ? rows[0] : {id:0}]}}
						pinnedColumns={{left: [GRID_TREE_DATA_GROUPING_FIELD]}}
					/>
				</div>
			</div>
		</div>
	)
}

export default ReportClienti