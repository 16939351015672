import React, {useContext, useState, useEffect} from 'react';
import './Talent.css';
import { useParams } from 'react-router-dom';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { CheckIcon, XIcon, AddIcon, EditIcon } from '../../../../assets/icons/icons';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import config from '../../../../config.ts';
import { AuthContext } from '../../../../contexts/authContext.tsx';
import CircleProgress  from '../../../../components/CircleProgress/CircleProgress';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel,
	GridToolbarContainer,
	GridToolbarExport
} from '@mui/x-data-grid-premium';
import { formatNumbersIt, getFormatDateFromDate } from '../../../../utils/CommonUtilities.ts';

LicenseInfo.setLicenseKey(
	'dd496cfc4b1b33990ea31063647a918dTz03ODE5MixFPTE3MzA5ODc5NDYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

const CellEsterno = (params:any) => {
  	return <div>{params.row.FLAG_ESTERNO==='N' ? <CheckIcon/> : <XIcon/>}</div>
}

const ActionHeader:any = () => {
	const navigate = useNavigate();
	return <IconButton onClick={()=>navigate('/talent/new')}><AddIcon addClass='font-16'/></IconButton>
}

const ActionCell:any = (params:any) => {
	const navigate = useNavigate();
	return <IconButton onClick={()=>navigate(`/talent/${params.id}`)}><EditIcon addClass='font-16'/></IconButton>
}

const columns:GridColDef[] = [
	{ field: 'COGNOME', headerClassName: 'header-cell cognome', headerName: "Cognome", type: 'text', width: 200, editable: false},
	{ field: 'NOME', headerClassName: 'header-cell nome', headerName: "Nome", type: 'text', width: 200, editable: false},
	{ field: 'NOME_ARTE', headerClassName: 'header-cell nomeArte', headerName: "Nome d'arte", type: 'text', width: 200, editable: false},
	{ field: 'E_MAIL', headerClassName: 'header-cell email', headerName: "Email", type: 'text', width: 250, editable: false},
	{ field: 'PERC_FEE_DEFAULT', headerClassName: 'header-cell percFeeDefault', headerName: "% Fee Default", type: 'text', width: 200, editable: false, align:'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
	{ field: 'FLAG_ESTERNO', headerClassName: 'header-cell flagEsterno', headerName: "Talent Interno", type: 'text', width: 200, editable: false, align: 'center', headerAlign: 'center', renderCell: (params:any) => CellEsterno(params)},
	{ field: 'ACTION', renderHeader: ()=>ActionHeader(), headerClassName: 'header-cell codiceSdi', headerName: "", type: 'text', width: 60, align: 'center', headerAlign: 'center', editable: false, sortable: false,
		renderCell: (params:any) => ActionCell(params),
		disableColumnMenu: true,
		disableExport: true
	}
];

function CustomToolbar() {
	const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
				excelOptions={{
					fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
				}}
			/>
		</GridToolbarContainer>
	);
}

function Talent(props:any) {

	const auth = useContext(AuthContext)
	const { setPageName } = useContext(AppAuthGlobalVariables);
	const urlParams = useParams();
	const [rows, setRows] = useState<GridRowModel[]>([]);
	const [ loading , setLoading ] = useState(true);

  	useEffect(() => {
		setPageName?.(urlParams['*']);
		async function init(){
			await getTalent();
		}
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
  	},[])

	const getTalent = async() => {
		try {    
			let axiosParams = {
				url		: 'talents',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
				setRows(response.data)
			}

			setLoading(false)
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

  	return (
    	<div className='component-container talent'>
      		{ loading && ( <CircleProgress/>)}
        	<div className='component-card'>
				<DataGridPremium
					className='dataGrid'
					slots={{
						noRowsOverlay: NoRowsDataGridComponent,
						toolbar: CustomToolbar
					}}
					initialState={{ pinnedColumns: { left: ['ACTION'], right: [] } }}
					rows={rows}
					columns={columns}
					rowHeight={30}
					hideFooter={true}
					isCellEditable={(params) => params.id!==0}
					disableColumnMenu={false}
                    disableColumnReorder={true}
                    disableAggregation={true}
				/>
       	 	</div>
		</div>
  	)
}

export default Talent