import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './CircleProgress.css';

export default function CircleProgress(props:any) {
  return (
    <div className={props.isDialog ? 'loading-background-dialog' : 'loading-background'}>
        <div className='middlePage-Loader'>
            <div className='circularProgress'>
                <CircularProgress />
            </div>
        </div>
    </div>
  );
}