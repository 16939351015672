import moment from "moment";
import config from "../config";

let timeoutId:any = null;

export function inputOnlyNumbers(input:string){
    const regex = /^[0-9]*$/;
    
    return regex.test(input);
}

export function inputOnlyNumbersWithComma(input:string){
    const regex = /^[[0-9]*[,]?[0-9]*]*$/

    return regex.test(input);
}

export function testNumberInputPercentages(input:string){
    const regex = /^[1-9][0-9]?$|^100$/;

    return regex.test(input);
}

export function testPasswordValidator(input:string){
    const regexPasswordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
    
    return regexPasswordValidator.test( input );
}

export function testNumberInputPercentagesTwoDecimals(input:string){
    const regexPercentagesTwoDecimals = /(^100([.]0{1,2})?)$|(^\d{1,2}([,]\d{1,2})?)$/;
    
    return regexPercentagesTwoDecimals.test( input );
}

export function testEmail(input:string){
    const regexEmailValidator = /^[a-z0-9]+([.\-_]?[a-z0-9]+)+@[a-z0-9]+([.\-_]?[a-z0-9]+)+\.[a-z]{2,}$/;

    return regexEmailValidator.test(input);
}

export function formatNumbersIt(inputValue:any){
    if(inputValue===undefined)return '';
    return new Intl.NumberFormat('it-IT').format(inputValue);
}

export function formatNumbersItNoSeparator(inputValue:any){
    if(inputValue===undefined)return '';
    return new Intl.NumberFormat('it-IT',{useGrouping: false}).format(inputValue);
}

export function formatNum( value:number, decimals = 2, sZero = 0 ):any {
    return value.toFixed( decimals )
}

export function getFormatDateFromDate(inputDate: Date, separatorChar = '/'){
    //torna una stringa in formato dd/mm/yyyy a partire da un Date
    //separatore di default è / però indicarne uno diverso per i file generati siccome / è riservato
    let month = '' + (inputDate.getMonth() + 1);
    let day = '' + inputDate.getDate();
    let year = inputDate.getFullYear();

    if (month.length < 2)month = '0' + month;
    if (day.length < 2)day = '0' + day;

    return [day, month, year].join(separatorChar);
}

export function getFormatDate(inputDate:string) {
    //torna una stringa in formato dd/mm/yyyy a partire da una stringa formato 'YYYYMMDD...'

    let retVal = '';

    try {
        const yyyy = inputDate.substring(0, 4);
        const mm = inputDate.substring(4, 6);
        const dd = inputDate.substring(6, 8);

        retVal = `${dd}/${mm}/${yyyy}`;
        
    }catch (err:any) {
        //config.ENV === 'dev' && console.log('CommonUtilities - getFormatDate' + err.message);
    }

    return retVal;
}

export function getFormatDateTime(inputDate:string) {
    //torna una stringa in formato dd/mm/yyyy hh24:mi:ss a partire da una stringa formato 'YYYYMMDDHH24MISS'

    let retVal = '';

    try {
        const yyyy = inputDate.substring(0, 4);
        const mm = inputDate.substring(4, 6);
        const dd = inputDate.substring(6, 8);

        let hh= '';
        let mi= '';
        let ss= '';
        hh = inputDate.substring(8, 10);
        mi = inputDate.substring(10, 12);
        ss = inputDate.substring(12, 14);

        retVal = `${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`;
        
    }catch (err:any) {
        config.ENV === 'dev' && console.log('CommonUtilities - getFormatDateTime' + err.message);
    }

    return retVal;
}

export function getHoursUtc(){
    //torna una stringa formato 'hhmmss'  a partire da un Date UTC
    let data = new Date();

    let h = data.getHours()+'';
    h = h.length === 1 ? '0'+h : h;
    let m = data.getMinutes()+'';
    m = m.length === 1 ? '0'+m : m;
    let s = data.getSeconds()+'';
    s = s.length === 1 ? '0'+s : s;
    
    return `${h}${m}${s}`;
}

export function getStringFromDate(inputDate:Date) {
    //torna una stringa formato 'YYYYMMDD'  a partire da un Date

    let retVal = '';

    try {
        if ( moment( inputDate ).isValid() ) {
            const d = inputDate.getDate().toString().padStart(2, '0');
            const m = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const y = inputDate.getFullYear().toString().padStart(4, '0');
            retVal = `${y}${m}${d}`;
        }
    }catch (err:any) {
        config.ENV === 'dev' && console.log('CommonUtilities - getStringFromDate ' + err.message);
    }

    return retVal;
}

export function getStringMonthYearFromDate(inputDate:Date, fullText:boolean = false) {
    //torna una stringa formato 'YYYYMMDD'  a partire da un Date

    let retVal = '';

    try {
        if ( moment( inputDate ).isValid() ) {
            const m = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const y = inputDate.getFullYear().toString().padStart(4, '0');
            if(fullText){
                let arrMonths = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre']

                retVal = `${arrMonths[(parseInt(m)-1)]} ${y}`
            }else{
                retVal = `${y}${m}`;
            }
        }
    }catch (err:any) {
        config.ENV === 'dev' && console.log('CommonUtilities - getStringFromDate ' + err.message);
    }

    return retVal;
}

export function getDateFromString(inputDate: string) {
    //torna un Date a partire da una stringa formato 'YYYYMMDD'

    let retVal = null;

    try {
        retVal = new Date(parseInt(inputDate.substring(0, 4)), parseInt(inputDate.substring(4, 6)) - 1, parseInt(inputDate.substring(6, 8)));
    }catch (err:any) {
        config.ENV === 'dev' && console.log('CommonUtilities - getDateFromString' + err.message);
    }

    return retVal;
}

export function getDateFromBucketStringDate(inputDate: string){
    //torna una stringa in formato DD/MM/YYYY a partire da una stringa di un bucket in formato yyyy-mm-dd hh24:mi:ss

    let retVal = '';

    try {
        const yyyy = inputDate.substring(0, 4);
        const mm = inputDate.substring(5, 7);
        const dd = inputDate.substring(8, 10);

        retVal = `${dd}/${mm}/${yyyy}`;
        
    }catch (err:any) {
        config.ENV === 'dev' && console.log('CommonUtilities - getDateFromBucketStringDate' + err.message);
    }

    return retVal;
}
    
export function debounce(fn:any, time:any) {

    function wrapper(...args:any) {

        if ( timeoutId ) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(
            () => {
                timeoutId = null;
                fn(...args);
            }, time
        );

    }

    return wrapper;
}