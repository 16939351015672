import React,{ useState, useContext, SyntheticEvent, useEffect, useRef } from 'react';
import { Link, Route, Routes, useNavigate }                      from 'react-router-dom';
import axios  from 'axios';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Menu              from '@mui/material/Menu';
import Popover           from '@mui/material/Popover';
import Divider           from '@mui/material/Divider';

import { AppAuthGlobalVariables } from '../../contexts/AppAuthContext';
import { AuthContext } from '../../contexts/authContext';
import CircleProgress  from '../../components/CircleProgress/CircleProgress';
import config          from '../../config';
import './index.css';

import {ReactComponent as SysCubeLogo}  from '../../assets/svgs/systrategy_cube_logo.svg';
import {ReactComponent as SysWordLogo}  from '../../assets/svgs/systrategy_word_logo.svg';
import DoomLogo2 from '../../assets/svgs/DOOM-logo-small.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Agenzie                from './anagrafiche/agenzie/Agenzie';
import AgenziaDettaglio       from './anagrafiche/agenzie/AgenziaDettaglio';
import Aree                   from './options/aree/Aree';
import CategorieCosti         from './options/categorieCosti/CategorieCosti';
import ChangePassword         from './userOptions/changePassword/ChangePassword';
import Clienti                from './anagrafiche/clienti/Clienti';
import ClienteDettaglio       from './anagrafiche/clienti/ClienteDettaglio';
import Dashboard              from './dashboard/Dashboard';
import ListaProposte          from './marketing/proposte/ListaProposte';
import Localita               from './options/localita/Localita';
import MfaSettings            from './userOptions/mfaSettings/MfaSettings';
import WrapperProposta        from './marketing/proposte/WrapperProposta';
import Page403                from './errorPages/Page403';
import Page404                from './errorPages/Page404';
import Page500                from './errorPages/Page500';
import RefProgetto            from './anagrafiche/refProgetto/RefProgetto';
import Talent                 from './anagrafiche/talent/Talent';
import TalentDettaglio        from './anagrafiche/talent/TalentDettaglio';
import UserDetail             from './anagrafiche/utenti/UserDetail';
import Utenti                 from './anagrafiche/utenti/Utenti';

import Collapse from '@mui/material/Collapse';

import {    
    AccountCircleSolidIcon,
    AgencyLightIcon,
    AreaLightIcon,
    CategoriesCostiLightIcon,
    CategorieMercLightIcon,
    ClientLightIcon,
    DashBoardLightIcon,
    KeyIcon,
    LocationLightIcon,
    LogoutIcon,
    OrderLightIcon,
    ProductLightIcon,
    ReferenteLightIcon,
    TalentLightIcon,
    UsersLightIcon,
    ImpersonateIcon,
    ImpersonateIconEnd,
    ScadenzarioIcon,
    ReportIcon,
    NotificationImportantIcon,
    NotificationIcon,
    NotificationIconSolid,
    DownLoadIcon,
    HourglassIcon
} from '../../assets/icons/icons';
import { NotificationManager } from 'react-notifications';
import Impersonate from './userOptions/impersonate/Impersonate';
import Scadenzario from './marketing/scadenzario/Scadenzario';
import ReportAgenzie from './reportistica/reportAgenzie/ReportAgenzie';
import ReportClienti from './reportistica/reportClienti/ReportClienti';
import ReportProposteUscita from './reportistica/reportProposteUscita/ReportProposteUscita';
import ReportSettori from './reportistica/reportSettori/ReportSettori';
import ReportTalents from './reportistica/reportTalents/ReportTalents';

import {
	Dialog
   ,DialogActions
   ,DialogContent
   ,DialogTitle
   ,Button
   ,IconButton
} from '@mui/material';
import ReportAndamento from './reportistica/reportAndamento/ReportAndamento';
import ReportDn from './reportistica/reportDn/ReportDn';
import ReportFatturato from './reportistica/reportFatturato/ReportFatturato';

function useInterval(callback:any, delay:number) {
    const savedCallback:any = useRef();
   
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
   
    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function MainIndex() {

    const navigate = useNavigate();
    const { pageName, userEmail, setUserEmail } = useContext(AppAuthGlobalVariables);
    const auth = useContext(AuthContext);
    const MFAtoConfig:boolean = localStorage.getItem('isMFAToConfig') === 'true';

    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo?.accessToken}`
    };

    const [ loading, setLoading ] = useState(true);
    const [ sideHover, setSideHover ] = useState(false);

    const [hardLoading, setHardLoading] = useState(false);

    const [ anchorUserSetting ,setAnchorUserSetting  ] = useState<HTMLInputElement | null>(null);
    const [ anchorSettings    ,setAnchorSettings     ] = useState<HTMLInputElement | null>(null);
    const [ anchorNotify      ,setAnchorNotify       ] = useState<HTMLInputElement | null>(null);

    const [ isCollapseOpen, setIsCollapseOpen ] = useState(false);
    const [ isCollapseReportOpen, setIsCollapseReportOpen ] = useState(false);

    const [ selectedPage, setSelectedPage ] = useState('dashboard');

    const [ openAnagrafiche, setOpenAnagrafiche ] = useState(false);
    const [ openSettings, setOpenSettings] = useState(false);

    const [ allPropCounter, setAllPropCounter ] = useState<number | string>('');
    const [ leadsPropCounter, setLeadsPropCounter ] = useState<number | string>('');
    const [ confermatePropCounter, setConfermatePropCounter ] = useState<number | string>('');
    const [ contractsPropCounter, setContractsPropCounter ] = useState<number | string>('');
    const [ contractsInModPropCounter, setContractsInModPropCounter ] = useState<number>(0);
    const [ closePropCounter, setClosePropCounter ] = useState<number | string>('');
    const [ rejectedPropCounter, setRejectedPropCounter ] = useState<number | string>('');
    const [ deletedPropCounter, setDeletedPropCounter ] = useState<number | string>('');

    const [ adminUser, setAdminUser ] = useState<boolean>(false);
    const [ isUserInImpersonate, setIsUserInImpersonate ] = useState<boolean>(false);

    const [ openDialogNotifications, setOpenDialogNotifications ] = useState<boolean>(false);
    const [ arrMessages, setArrMessages ] = useState<any>([]);
    const [ loadingDialog, setLoadingDialog ] = useState<any>(false);
    const [ isYellowBellToShow, setIsYellowBellToShow ] = useState<any>(false);

    //filtri Lista Proposte
    const [ anno, setAnno ] = useState<any>(new Date().getFullYear().toString());
    const [ dAgenzia, setDAgenzia ] = useState<any>('');
    const [ dCliente, setDCliente ] = useState<any>('');
    const [ dReferenteCommerciale, setDReferenteCommerciale ] = useState<any>('');
    const [ dTalent, setDTalent ] = useState<any>('');
    const [ oPeriodo, set_oPeriodo ] = useState<any>({ sDataInizio: null, sDataFine: null });
    const [ idPropostaFiltro, setIdPropostaFiltro] = useState<any>('');

    const [ kAgenzia, setKAgenzia ] = useState<any>('');
    const [ kCliente, setKCliente ] = useState<any>('');
    const [ kReferenteCommerciale, setKReferenteCommerciale ] = useState<any>('');
    const [ kTalent, setKTalent ] = useState<any>('');

    useEffect(() => {
        async function init () {

            setIsUserInImpersonate(false);
            localStorage.removeItem('impersonateKUser');
            await Promise.all([
                getProposte(),
                getProfile()
            ]).then(values => {

            }).catch((e:any) => {
                if(e.response.status===500){
                    NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
                }else if(e.response.status===401){
                    NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                    logOut();
                }else{
                    NotificationManager.error('Errore di Rete', 'Errore', 10000);
                }
            });

            if(localStorage.getItem('nazioni')===null){
                setHardLoading(true);
                await getLocations(1);
            }
            if(localStorage.getItem('comuni')===null){
                setHardLoading(true);
                await getLocations(4);
            }

            let loggedEmail:string | undefined = '';
            let userAttributes:Array<{Name: string, Value: string}> = await auth.getAttributes();
            let emailUserObj = userAttributes.find(attribute => attribute.Name === 'email');
            loggedEmail = emailUserObj?.Value;
            setUserEmail?.(loggedEmail);
            setHardLoading(false);
        }
        init();
        setLoading(false);
    },[])

    useInterval( () => {
        if(document.visibilityState==="visible"){
            getMessagesFiles();
        }
    }, 25000);

    const getMessagesFiles = async() => {
        try{
            let axiosParams = {
                url: 'check-queue',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                //timeout: 3000
            }

            let response = await axios(axiosParams);

            let processedMessages:any = [];
            let finalProcessedMessages:any = [];
            if(!response.data){
                //console.log('  NESSUN MESSAGGIO TROVATO    ');
                return;
            }
            let receivedMessages = response.data.Messages || [];

            let requestFiles = receivedMessages.filter((msg:any) => msg.Body.split('¶')[3] === 'req');
            let doneFiles = receivedMessages.filter((msg:any) => msg.Body.split('¶')[3] === 'done');

            for (let index = 0; index < requestFiles.length; index++) {
                const requestElement = requestFiles[index];
                const requestElementSplit = requestElement.Body.split('¶');
                
                let foundDoneByIdTime = doneFiles.find((el:any) => ((el.Body.split('¶')[0] === requestElementSplit[0]) && (el.Body.split('¶')[2] === requestElementSplit[2])));

                if( foundDoneByIdTime === undefined ){
                    //se non trovo il suo done, lo tengo
                    processedMessages.push(requestElement);
                }else{
                    //se lo trovo lo cancello dalla coda perché non serve
                    deleteMessages(requestElement.ReceiptHandle);
                }
            }
            //file req e done
            processedMessages = [...processedMessages, ...doneFiles];

            //se la memoria è vuota la riempo con la response processata
            if(arrMessages.length === 0){
                //solo se la response processata presenta dei messaggi done si accende la campana
                if(doneFiles.length)setIsYellowBellToShow(true);
                setArrMessages([...processedMessages]);
                return;
            }

            let memoryMessagesRequest = arrMessages.filter((msg:any) => msg.Body.split('¶')[3] === 'req');
            let memoryMessagesDone = arrMessages.filter((msg:any) => msg.Body.split('¶')[3] === 'done');

            for (let index = 0; index < memoryMessagesRequest.length; index++) {
                const memoryRequestElement = memoryMessagesRequest[index];
                const memoryRequestElementSplit = memoryRequestElement.Body.split('¶');
                
                let foundDoneByIdTime = doneFiles.find((el:any) => ((el.Body.split('¶')[0] === memoryRequestElementSplit[0]) && (el.Body.split('¶')[2] === memoryRequestElementSplit[2])));

                if( foundDoneByIdTime === undefined){
                    //così tengo i req che ho in memoria di cui non ci sono done nella response
                    finalProcessedMessages.push(memoryRequestElement)
                }else{
                    deleteMessages(memoryRequestElement.ReceiptHandle);
                }
            }

            finalProcessedMessages = [...finalProcessedMessages, ...processedMessages, ...memoryMessagesDone];

            const finalResult = Object.values(

                //verifico univocità sul timestamp per evitare di perdere i doppioni per id
                finalProcessedMessages.reduce((acc:any, obj:any) => ({ ...acc, [obj.Body.split('¶')[2]]: obj }), {})
            );

            let counterDoneMessages = finalResult.filter(((el:any) => el.Body.split('¶')[3] === 'done')).length;

            if(counterDoneMessages > memoryMessagesDone.length){
                setIsYellowBellToShow(true);
            }
            setArrMessages([...finalResult]);

        }catch(e){
            //console.log(e)
        }
    }

    const deleteMessages = async(msgReceipt:string) => {
        try{
            let axiosParams = {
                url     : 'delete-message',
                method  : 'post',
                baseURL : config.API_URL,
                headers : {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data    : {
                    receipt: msgReceipt
                }
            }

            await axios(axiosParams);
        }catch(e){
            console.log(e)
        }
    }

    const getProfile = async() => {
        try{
            let axiosParams = {
                url: 'profile',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
            }

            let response = await axios(axiosParams);

            if(response.status === 200){
                setAdminUser(response.data.FLAG_ADMIN === 'Y' ? true : false);
                setUserEmail?.(response.data.EMAIL_ADDRESS);
                localStorage.setItem('admin', response.data.FLAG_ADMIN);
                localStorage.setItem('talent_manager', response.data.FLAG_TALENT_MANAGER);
                localStorage.setItem('referente_commerciale', response.data.FLAG_REF_COMM);
                localStorage.setItem('amministrazione', response.data.FLAG_AMMINISTRAZIONE);
            }
        }catch (e:any){
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    const getProposte = async() => {
        try{
            let axiosParams = {
                url: 'proposte',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
            }

            let response = await axios(axiosParams);

            if(response.status === 200){

                let proposals = [...response.data];
                    
                setAllPropCounter(proposals[0].N_TOT);
                setLeadsPropCounter(proposals[0].N_LEAD);
                setConfermatePropCounter(proposals[0].N_CONF);
                setContractsPropCounter(proposals[0].N_CONTR_TOT);
                setContractsInModPropCounter(proposals[0].N_CONTR_MOD);
                setClosePropCounter(proposals[0].N_CHIUSE);
                setRejectedPropCounter(proposals[0].N_RIF);
                setDeletedPropCounter(proposals[0].N_CANC);
            }
        }catch (e:any){
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        } 
    }

    const handleHover = (action:string) => {
        if (action === 'in') {
            setSideHover(true);
        } else {
            setSideHover(false);
        }
    }

    const goToHome = () => {
        navigate('/dashboard');
    };

    const handleOpen = ( event: SyntheticEvent, target: string ) =>{
        let targetCoords = (event.currentTarget as HTMLInputElement | null );
        switch ( target ) {
            case 'user-settings':
                setAnchorUserSetting(targetCoords);
                break;

            case 'notify':
                setAnchorNotify(targetCoords);
                break;                

            case 'settings':
                setAnchorSettings(targetCoords);
                break;

            default:
                console.log('hmm, mumble...');
                break;
        }
    };

    const handleClose = (target?: string) => {
        switch ( target ) {
            case 'user-settings':
                setAnchorUserSetting(null);
                break;

            case 'notify':
                setAnchorNotify(null);
                break;                

            case 'settings':
                setAnchorSettings(null);
                break;

            default:
                console.log('hmm, mumble...');
                break;
        }
    };

    const goToChangePassword = () => {
        handleClose();
        navigate('/change-password');
    }

    const goToImpersonate = () => {
        handleClose();
        navigate('/impersona');
    }

    const logOut = () =>{
        handleClose();
        auth.signOut();
        navigate('/login', {replace: false});
    }

    const getLocations = async(depth:number) => {
		try{
            let axiosParams = {
                url: 'locations',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                    depth
                }
            }

            let response = await axios(axiosParams);

            if ( response.status === 200 ) {
                if ( depth === 1 ) {
                    localStorage.setItem('nazioni', JSON.stringify(response.data));
                } else if ( depth === 4 ) {
                    localStorage.setItem('comuni', JSON.stringify(response.data));
                }
            }

		} catch( e:any ) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
    }

    const endImpersonate = async() => {
        setIsUserInImpersonate(false);
        localStorage.removeItem('impersonateKUser');
        await getProfile();
        let loggedEmail:string | undefined = '';
        let userAttributes:Array<{Name: string, Value: string}> = await auth.getAttributes();
        let emailUserObj = userAttributes.find(attribute => attribute.Name === 'email');
        loggedEmail = emailUserObj?.Value;
        setUserEmail?.(loggedEmail);
    }

    const handleOpenNotificationsDialog = () => {
        setIsYellowBellToShow(false);
        setOpenDialogNotifications(true);
    }

    const handleCloseNotificationsDialog = () => {
        setOpenDialogNotifications(false);
    }

    const downloadFileRequested = (kProposta:any) => {
        try{
			setLoadingDialog(true);
			fetch(config.ATTACHMENTS_URL+'/usersGeneratedFiles/'+kProposta+'.docx', {
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
				  	"Content-Type": "application/json",
				  	authorization: `${auth.sessionInfo?.accessToken}`
				},
			  }).then(resp => resp.blob())
			  	.then(blob => {
						const url = window.URL.createObjectURL(blob);
						const a = document.createElement("a");
						a.style.display = "none";
						a.href = url;
						a.download = 'preventivo_'+kProposta+'.docx';
						document.body.appendChild(a);
						a.click();
						window.URL.revokeObjectURL(url);
                        setLoadingDialog(false);
					})
			  	.catch((error) => console.error(error));
		}catch(e:any){
			NotificationManager.error('Errore nel Download del file', '', 10000);
		}
    }

    if ( MFAtoConfig ) {
        navigate('/mfa-setup');
        return (
            <div className={"main-background"}>
                {
                    loading ? <CircleProgress /> : 
                    <>
                        <div className="main-content-mfa">
                            <div className="header-config-mfa">
                                <div className="header-container">
                                    <div>
                                        <span className="app-name cursor-defaul">Business Manager</span>
                                        <span className="app-name page-name cursor-defaul"> | {pageName} </span>
                                    </div>
                                    {/* dropdown-user-settings */}
                                    <Button className="logout-button-container dropdown-user-options" onClick={logOut} startIcon={<LogoutIcon />}>Esci</Button>
                                </div>
                            </div>
                            <Routes>  
                                <Route path="/*"  element={<MfaSettings/>}/>
                            </Routes>
                        </div>
                    </>
                }
            </div>
        )
    } else if(hardLoading){
        return(
            <div className={"main-background"} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <CircularProgress />
            </div>
        )
    } else {
        return (
            <div className={"main-background"}>
                {
                    loading ? <CircleProgress /> : <>
                        <aside onMouseEnter={() => handleHover('in')} onMouseLeave={() => handleHover('out')}>
                            <div className="logo-and-menu-container">
                                <div className="side-menu-logo">
                                    <img src={ DoomLogo2  } alt="logo" className="logoImage"/>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="/dashboard" className="linkMenu" onClick={() => setSelectedPage('dashboard')}>
                                            <div className={`side-menu-options ${selectedPage==='dashboard' ? 'last-voice-selected' : ''}`}>
                                                <DashBoardLightIcon/>
                                                {sideHover && <div>Dashboard</div>}
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="linkMenu anchor-custom"  onClick={()=> {setIsCollapseOpen(!isCollapseOpen)}}>
                                            <div className="side-menu-options">
                                                <OrderLightIcon/>
                                                {sideHover && (
                                                    <div className='adjust-proposal-menu'>
                                                        <span>Proposte</span>
                                                        {isCollapseOpen ? 
                                                        <i className='icon fa-regular fa-chevron-right font-20 no-pad'/> 
                                                        :
                                                        <i className='icon fa-regular fa-chevron-down font-20 no-pad'/>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {sideHover  &&(
                                            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit className='collapsed'>
                                                <Link to="/proposte" className="linkMenu" onClick={() => setSelectedPage('tutte')}>   
                                                    <div className={`side-menu-options ${selectedPage==='tutte' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Tutte (${allPropCounter})`}</div>
                                                    </div>
                                                </Link>
                                                <Link to="/proposte/01" className="linkMenu" onClick={() => setSelectedPage('01')}>   
                                                    <div className={`side-menu-options ${selectedPage==='01' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Lead (${leadsPropCounter})`}</div>
                                                    </div>
                                                </Link>
                                                <Link to="/proposte/02" className="linkMenu" onClick={() => setSelectedPage('02')}>
                                                    <div className={`side-menu-options ${selectedPage==='02' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Confermate (${confermatePropCounter})`}</div>
                                                    </div>
                                                </Link>
                                                <Link to="/proposte/04" className="linkMenu" onClick={() => setSelectedPage('04')}>
                                                    <div className={`side-menu-options ${selectedPage==='04' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Contrattualizzate (${contractsPropCounter})`}<span className='red-txt'>{contractsInModPropCounter > 0 ? `(${contractsInModPropCounter})` : ''}</span></div>
                                                    </div>
                                                </Link>
                                                <Link to="/proposte/05" className="linkMenu" onClick={() => setSelectedPage('05')}>
                                                    <div className={`side-menu-options ${selectedPage==='05' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Chiuse (${closePropCounter})`}</div>
                                                    </div>
                                                </Link>
                                                <Link to="/proposte/03" className="linkMenu" onClick={() => setSelectedPage('03')}>
                                                    <div className={`side-menu-options ${selectedPage==='03' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Rifiutate (${rejectedPropCounter})`}</div>
                                                    </div>
                                                </Link>
                                                <Divider/>
                                                <Link to="/proposte/07" className="linkMenu" onClick={() => setSelectedPage('07')}>
                                                    <div className={`side-menu-options ${selectedPage==='07' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>{`Cancellate (${deletedPropCounter})`}</div>
                                                    </div>
                                                </Link>
                                            </Collapse>
                                        )}
                                        <Link to="/scadenzario" className="linkMenu" onClick={() => setSelectedPage('scadenzario')}>
                                            <div className={`side-menu-options ${selectedPage==='scadenzario' ? 'last-voice-selected' : ''}`}>
                                                <ScadenzarioIcon/>
                                                {sideHover && <div>Scadenzario</div>}
                                            </div>
                                        </Link>
                                    </li>
                                    <li className='flex-menu-block'>
                                        <div className="linkMenu anchor-custom"  onClick={()=> {setIsCollapseReportOpen(!isCollapseReportOpen)}}>
                                            <div className="side-menu-options">
                                                <ReportIcon/>
                                                {sideHover && (
                                                    <div className='adjust-proposal-menu'>
                                                        <span>Report</span>
                                                        {isCollapseReportOpen ? 
                                                        <i className='icon fa-regular fa-chevron-right font-20 no-pad'/> 
                                                        :
                                                        <i className='icon fa-regular fa-chevron-down font-20 no-pad'/>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {sideHover  &&(
                                            <Collapse in={isCollapseReportOpen} timeout="auto" unmountOnExit className='collapsed'>
                                                <Link to="/report/andamento" className="linkMenu" onClick={() => setSelectedPage('report-andamento')}>   
                                                    <div className={`side-menu-options ${selectedPage==='report-andamento' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Andamento</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/agenzie" className="linkMenu" onClick={() => setSelectedPage('report-agenzie')}>   
                                                    <div className={`side-menu-options ${selectedPage==='report-agenzie' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Agenzie</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/clienti" className="linkMenu" onClick={() => setSelectedPage('report-clienti')}>
                                                    <div className={`side-menu-options ${selectedPage==='report-clienti' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Clienti</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/dn" className="linkMenu" onClick={() => setSelectedPage('report-dn')}>
                                                    <div className={`side-menu-options ${selectedPage==='report-dn' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>DN</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/fatturato" className="linkMenu" onClick={() => setSelectedPage('report-fatturato')}>
                                                    <div className={`side-menu-options ${selectedPage==='report-fatturato' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Fatturato</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/proposte-uscita" className="linkMenu" onClick={() => setSelectedPage('report-proposte-uscita')}>
                                                    <div className={`side-menu-options ${selectedPage==='report-proposte-uscita' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Proposte in Uscita</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/settori" className="linkMenu" onClick={() => setSelectedPage('report-settori')}>
                                                    <div className={`side-menu-options ${selectedPage==='report-settori' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Settori</div>
                                                    </div>
                                                </Link>
                                                <Link to="/report/talents" className="linkMenu" onClick={() => setSelectedPage('report-talents')}>
                                                    <div className={`side-menu-options ${selectedPage==='report-talents' ? 'last-voice-selected' : ''}`}>
                                                        <div className='collapsed-element'>Talent</div>
                                                    </div>
                                                </Link>
                                            </Collapse>
                                        )}
                                    </li>
                                    <li className='flex-menu-block'>
                                        <Link to="/agenzie" className="linkMenu" onClick={() => setSelectedPage('agenzie')}>
                                            <div className={`side-menu-options ${selectedPage==='agenzie' ? 'last-voice-selected' : ''}`}>
                                                <AgencyLightIcon/>
                                                {sideHover && <div>Agenzie</div>}
                                            </div>
                                        </Link>
                                        <Link to="/clienti" className="linkMenu" onClick={() => setSelectedPage('clienti')}>
                                            <div className={`side-menu-options ${selectedPage==='clienti' ? 'last-voice-selected' : ''}`}>
                                                <ClientLightIcon/>
                                                {sideHover && <div>Clienti</div>}
                                            </div>
                                        </Link>
                                        <Link to="/referenti" className="linkMenu" onClick={() => setSelectedPage('referenti')}>
                                            <div className={`side-menu-options ${selectedPage==='referenti' ? 'last-voice-selected' : ''}`}>
                                                <ReferenteLightIcon/>
                                                {sideHover && <div>Referenti Progetto</div>}
                                            </div>
                                        </Link>
                                        <Link to="/talent" className="linkMenu" onClick={() => setSelectedPage('talent')}>
                                            <div className={`side-menu-options ${selectedPage==='talent' ? 'last-voice-selected' : ''}`}>
                                                <TalentLightIcon/>
                                                {sideHover && <div>Talent</div>}
                                            </div>
                                        </Link>
                                        {adminUser&&(
                                            <Link to="/utenti" className="linkMenu" onClick={() => setSelectedPage('utenti')}>
                                                <div className={`side-menu-options ${selectedPage==='utenti' ? 'last-voice-selected' : ''}`}>
                                                    <UsersLightIcon/>
                                                    {sideHover && <div>Utenti</div>}
                                                </div>
                                            </Link>
                                        )}
                                    </li>
                                    {adminUser&&(<>
                                        <li className='flex-menu-block'>
                                            <Link to="/aree" className="linkMenu" onClick={() => setSelectedPage('aree')}>
                                                <div className={`side-menu-options ${selectedPage==='aree' ? 'last-voice-selected' : ''}`}>
                                                    <AreaLightIcon/>
                                                    {sideHover && <div>Aree Talent</div>}
                                                </div>
                                            </Link>
                                            <Link to="/categorie-costi" className="linkMenu" onClick={() => setSelectedPage('costi')}>
                                                <div className={`side-menu-options ${selectedPage==='costi' ? 'last-voice-selected' : ''}`}>
                                                    <CategoriesCostiLightIcon/>
                                                    {sideHover && <div>Categorie Costi</div>}
                                                </div>
                                            </Link>
                                        </li>
                                        <li className='flex-menu-block'>
                                            <Link to="/localita" className="linkMenu" onClick={() => setSelectedPage('comuni')}>
                                                <div className={`side-menu-options ${selectedPage==='comuni' ? 'last-voice-selected' : ''}`}>
                                                    <LocationLightIcon/>
                                                    {sideHover && <div>Comuni</div>}
                                                </div>
                                            </Link>
                                        </li>
                                    </>)}
                                </ul>
                            </div>
                            <a href='https://www.systrategy.it/' target='_blank' rel="noreferrer" className="logo-container roobeekPath">
                                <div style={{width: '30%'}}><SysCubeLogo style={{ paddingLeft:'10px'}}/></div>
                                <div className='logo-wrapper' style={{width: '80%' }}>{sideHover && (<SysWordLogo/>)}</div>
                            </a>
                        </aside>
                        <div className="main-content">
                            <div className="header">
                                <div className="header-container">
                                    <div>
                                        <span className="app-name cursor-pointer" onClick={goToHome}>Business Manager</span>
                                        <span className="app-name cursor-default page-name"> | {pageName} </span>
                                    </div>
                                    <div className="header-container-tools">
                                        <div>
                                            {isUserInImpersonate && (<ImpersonateIcon addClass='font-20'/>)}
                                        </div>
                                        <div style={{position:'relative'}}>
                                            {isYellowBellToShow ?
                                                <IconButton onClick={handleOpenNotificationsDialog}>
                                                    <NotificationIconSolid addClass='yellow-bell-notification'/>
                                                </IconButton>
                                            :
                                                <IconButton onClick={handleOpenNotificationsDialog}>
                                                    <NotificationIcon addClass='font-20'/>
                                                </IconButton>
                                            }
                                        </div>
                                        <div className="user-settings-wrapper cursor-pointer" onClick={(event) => handleOpen(event, 'user-settings')}>
                                            <span>{userEmail}</span>
                                            <AccountCircleSolidIcon />
                                            <Popover
                                                open={Boolean(anchorUserSetting)}
                                                anchorEl={anchorUserSetting}
                                                onClose={() => handleClose('user-settings')}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ClickAwayListener onClickAway={() => handleClose('user-settings')}>
                                                    <div className="dropdown-user-settings">
                                                        <Button className="dropdown-user-options" onClick={goToChangePassword} startIcon={<KeyIcon/>}>Cambia Password</Button>
                                                        {(adminUser || isUserInImpersonate)&&
                                                            <Button 
                                                                className="dropdown-user-options"
                                                                onClick={isUserInImpersonate ? endImpersonate : goToImpersonate}
                                                                startIcon={isUserInImpersonate ? <ImpersonateIconEnd/> : <ImpersonateIcon/>}>
                                                                    {isUserInImpersonate ? 'Fine Impersona' : 'Impersona'}
                                                            </Button>}
                                                        <Button className="dropdown-user-options" onClick={logOut} startIcon={<LogoutIcon />}>Esci</Button>
                                                    </div>
                                                </ClickAwayListener>
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Routes>
                                <Route path="/"           element={<Dashboard {...{logOut}}/>}/>
                                <Route path="/dashboard"  element={<Dashboard {...{logOut}}/>}/>

                                <Route path="/proposte"      element={<ListaProposte 
                                                                                {...{setAllPropCounter,
                                                                                setLeadsPropCounter,
                                                                                setConfermatePropCounter,
                                                                                setContractsPropCounter,
                                                                                setContractsInModPropCounter,
                                                                                setClosePropCounter,
                                                                                setRejectedPropCounter,
                                                                                setDeletedPropCounter,
                                                                                logOut,
                                                                                anno,
                                                                                setAnno,
                                                                                dAgenzia,
                                                                                setDAgenzia,
                                                                                dCliente,
                                                                                setDCliente,
                                                                                dReferenteCommerciale,
                                                                                setDReferenteCommerciale,
                                                                                dTalent,
                                                                                setDTalent,
                                                                                oPeriodo,
                                                                                set_oPeriodo,
                                                                                kAgenzia,
                                                                                setKAgenzia,
                                                                                kCliente,
                                                                                setKCliente,
                                                                                kReferenteCommerciale,
                                                                                setKReferenteCommerciale,
                                                                                kTalent,
                                                                                setKTalent,
                                                                                idPropostaFiltro,
                                                                                setIdPropostaFiltro}}/>}/>
                                <Route path="/proposte/:status"      element={<ListaProposte 
                                                                                {...{setAllPropCounter,
                                                                                setLeadsPropCounter,
                                                                                setConfermatePropCounter,
                                                                                setContractsPropCounter,
                                                                                setContractsInModPropCounter,
                                                                                setClosePropCounter,
                                                                                setRejectedPropCounter,
                                                                                setDeletedPropCounter,
                                                                                logOut,
                                                                                anno,
                                                                                setAnno,
                                                                                dAgenzia,
                                                                                setDAgenzia,
                                                                                dCliente,
                                                                                setDCliente,
                                                                                dReferenteCommerciale,
                                                                                setDReferenteCommerciale,
                                                                                dTalent,
                                                                                setDTalent,
                                                                                oPeriodo,
                                                                                set_oPeriodo,
                                                                                kAgenzia,
                                                                                setKAgenzia,
                                                                                kCliente,
                                                                                setKCliente,
                                                                                kReferenteCommerciale,
                                                                                setKReferenteCommerciale,
                                                                                kTalent,
                                                                                setKTalent,
                                                                                idPropostaFiltro,
                                                                                setIdPropostaFiltro}}/>}/>
                                <Route path="/proposta/:id"  element={<WrapperProposta menuVoiceSelected={selectedPage} {...{logOut}}/>}/>

                                <Route path="/scadenzario" element={<Scadenzario {...{logOut}}/>}/>

                                <Route path="/report/agenzie" element={<ReportAgenzie {...{logOut}}/>}/>
                                <Route path="/report/andamento" element={<ReportAndamento {...{logOut}}/>}/>
                                <Route path="/report/clienti" element={<ReportClienti {...{logOut}}/>}/>
                                <Route path="/report/dn" element={<ReportDn {...{logOut}}/>}/>
                                <Route path="/report/fatturato" element={<ReportFatturato {...{logOut}}/>}/>
                                <Route path="/report/proposte-uscita" element={<ReportProposteUscita {...{logOut}}/>}/>
                                <Route path="/report/settori" element={<ReportSettori {...{logOut}}/>}/>
                                <Route path="/report/talents" element={<ReportTalents {...{logOut}}/>}/>

                                <Route path="/agenzie"            element={<Agenzie {...{logOut}}/>}/>
                                <Route path="/agenzie/:id"        element={<AgenziaDettaglio {...{logOut}}/>}/>
                                <Route path="/clienti"            element={<Clienti {...{logOut}}/>}/>
                                <Route path="/clienti/:id"        element={<ClienteDettaglio {...{logOut}}/>}/>
                                <Route path="/referenti"          element={<RefProgetto {...{logOut}}/>}/>
                                <Route path="/talent"             element={<Talent {...{logOut}}/>}/>
                                <Route path="/talent/:id"         element={<TalentDettaglio {...{logOut}}/>}/>
                                {adminUser&&(<>
                                    <Route path="/utenti"             element={<Utenti {...{logOut}}/>}/>
                                    <Route path="/utenti/:id"         element={<UserDetail {...{logOut}}/>}/>

                                    <Route path="/aree"                    element={<Aree {...{logOut}}/>}/>
                                    <Route path="/categorie-costi"         element={<CategorieCosti {...{logOut}}/>}/>
                                    <Route path="/localita"                element={<Localita {...{logOut}}/>}/>
                                    {!isUserInImpersonate&&(<Route path="/impersona" element={<Impersonate {...{setIsUserInImpersonate, setAdminUser, logOut}}/>}/>)}
                                </>)}
                                
                                <Route path="/change-password" element={<ChangePassword {...{logOut}}/>}/>

                                <Route path="/500"  element={<Page500/>}/>
                                <Route path="/403"  element={<Page403/>}/>
                                <Route path="/*"    element={<Page404/>}/>
                            </Routes>
                        </div>
                    </>
                }
                <Dialog open={ openDialogNotifications } onClose={ handleCloseNotificationsDialog } className='notifications-dialog'>
                    <DialogTitle className='dialogueTitleSovrascriviDn'>
                        Notifiche
                    </DialogTitle>
                    <DialogContent className='notifications-dialog-wrapper'>
                    {loadingDialog && <CircleProgress isDialog={true}/>}
                        <div className='notifications-dialog-container'>
                           
                            {arrMessages.length > 0 ?
                                arrMessages.map((msg:any, index:number) =>
                                {
                                    let splittedMsg = msg.Body.split('¶');
                                    let kProposta = splittedMsg[0];
                                    let oramin = splittedMsg[2];
                                    let status = splittedMsg[3];
                                    let cliente = splittedMsg[4];

                                    let ss = oramin.substring(4,6);
                                    let mm = oramin.substring(2,4);
                                    let hh = oramin.substring(0,2);
                                    let hhmmss = `${hh}:${mm}:${ss}`;

                                    return(
                                        <div className='notifications-row' key={index}>
                                            <div>
                                                <div className='kProposta' title={`${kProposta} - ${cliente}`}>{kProposta} - {cliente}</div>
                                                <div className='oramin'>{hhmmss}</div>
                                            </div>
                                            <IconButton onClick={status === 'done' ? () => {downloadFileRequested(kProposta)} : () => {} }>
                                                {status === 'done' ? <DownLoadIcon/> : <HourglassIcon/>}
                                            </IconButton>
                                        </div>
                                    )
                                }
                            ) : <div>Non ci sono file richiesti in lavorazione</div>
                            }
                        </div>
                    </DialogContent>
                    <DialogActions className='flex-box-between dn-mod'>
                        <Button autoFocus onClick={ handleCloseNotificationsDialog } >Chiudi</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default MainIndex;