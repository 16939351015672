import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { ArrowLeft, PercentageIcon, ActiveIcon, DisabledIcon, SaveIcon, AddIcon, XIcon } from '../../../../assets/icons/icons';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import config from '../../../../config';
import { AuthContext } from '../../../../contexts/authContext';
import { UrlParamsObject, AutocompleteClientsAgentsObject } from '../../../../utils/ConfigInterfaces';
import { formatNumbersIt, getFormatDateTime, inputOnlyNumbers, testNumberInputPercentages } from '../../../../utils/CommonUtilities';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel
} from '@mui/x-data-grid-premium';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';

function TalentDettaglio(props:any) {
    const auth = useContext(AuthContext)
    const navigate = useNavigate();
    const { setPageName } = useContext(AppAuthGlobalVariables);

    const urlParams:UrlParamsObject = useParams();
    const kTalent: number | string | undefined = urlParams?.id;
    const [ cognome   , setCognome    ] = useState("");
    const [ nome      , setNome       ] = useState("");
    const [ nomeArte  , setNomeArte   ] = useState("");
    const [ email     , setEmail      ] = useState("");
    const [ fee       , setFee        ] = useState("");
    const [ esterno   , setEsterno    ] = useState(false);
    const [ areeTalent, setAreeTalent ] = useState<any>([]);

    const [ targetRows, setTargetRows ] = useState<GridRowModel[]>([]);

    const [ areeTalentLista, setAreeTalentLista ] = useState<any>(null);

	const [ dataIns , setDataIns  ] = useState('');
    const [ dataMod , setDataMod  ] = useState('');
    const [ dataCanc, setDataCanc ] = useState('');

    const [ openDialogTarget, setOpenDialogTarget ] = useState(false);
    const [ dialogAnno, setDialogAnno ] = useState('');
    const [ dialogTarget, setDialogTarget ] = useState('');

    const [ onReload, setOnReload ] = useState<boolean | null>(null);
    const [ loading , setLoading  ] = useState(true);
    const [ loadingSubmit, setLoadingSubmit ] = useState(false);

    const ActionHeader:any = (params:any) => {
        return (loading ? 
                    <CircularProgress className='font-16 table-circular-progress' /> 
                    :
                    <IconButton onClick={handleOpenDialogTarget}>
                        <AddIcon addClass='font-16'/>
                    </IconButton>)
    }

    const ActionCell:any = (params:any) => {
		return (
			<div className='flexed-center full-width'>
				{loading ? 
					<CircularProgress className='font-16 table-circular-progress' /> 
					:
					<IconButton onClick={()=>delTalentTarget(params)}><XIcon addClass='font-16'/></IconButton>
				}
			</div>
		)
	}
    
    const columns: GridColDef[] = [
        { field: 'ACTION', renderHeader: (params)=>ActionHeader(params), headerClassName: 'header-cell actionCol', headerName: "", type: 'text', width: 55, editable: false, sortable: false, disableColumnMenu: true, headerAlign:'center', align: 'center', renderCell: (params) => ActionCell(params)},
        { field: 'ANNO', headerClassName: 'header-cell anno', headerName: "Anno", type: 'text', width: 150, headerAlign: "center", align: "center" },
        { field: 'TARGET', headerClassName: 'header-cell target', headerName: "Target", type: 'text', width: 150, align: 'right', headerAlign: 'right', editable: true, valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : ''}},
    ]

    useEffect(() => {
        async function init() {
			await getAree();
            if(kTalent!=='new'){
                await getTalentTargets();
            }else{
                setPageName?.('Nuovo Talent');
            }
        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        async function init() {
            if ( areeTalentLista !== null ) {
                if(kTalent!=='new') await getTalent();
                setOnReload(!onReload);
            }
        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ areeTalentLista ])

    useEffect(() => {
        if ( onReload !== null ) {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);
  
    const getAree = async() => {
        try {    
            let axiosParams = {
                url		: 'aree',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
                setAreeTalentLista([...response.data]);
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setAreeTalentLista([]);
        }
    }
  
    const getTalent = async() => {
        try {
            let axiosParams = {
                url		: 'talents',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: { kTalent }
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
	        	let data = response.data[0];
	        	setNome(data.NOME);
	        	setCognome(data.COGNOME);
	        	setNomeArte(data.NOME_ARTE);
	        	setEmail(data.E_MAIL);
	        	setFee(data.PERC_FEE_DEFAULT);
	        	setEsterno(data.FLAG_ESTERNO === 'Y' ? true : false );
	        	let listaAree:any = [];
                if(data.AREE !== ''){
                    listaAree =  (data.AREE || '').split(',');
                    setAreeTalent(areeTalentLista.filter((oArea:any) => listaAree.includes( oArea.id + '' )));
                }

	        	setDataIns(getFormatDateTime(data.DATA_INS));
	        	setDataCanc(getFormatDateTime(data.DATA_CANC));
	        	setDataMod(getFormatDateTime(data.DATA_ULTIMA_MOD));

                setPageName?.(data.NOME_ARTE);
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    const getTalentTargets = async() => {
        try {
            let axiosParams = {
                url		: 'talents-target',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: { kTalent }
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
                setTargetRows(response.data);
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    const submitProcedure = async() => {
        setLoadingSubmit(true)
        try{
            let response = undefined;

            const talentData:any = {
                nome,
                cognome,
                nomeArte,
                email,
                defaultFee: esterno ? '' : fee,
                flagEsterno: esterno,
                listaAree: areeTalent.map((area:any) => area.id).join(','),
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                    ...talentData
                }
            }

            if ( kTalent === 'new' ) {
                axiosParams.url = 'talent-ins';
                response = await axios(axiosParams);
            } else {
                axiosParams.url = 'talent-mod';
                axiosParams.data.kTalent = kTalent;
                response = await axios(axiosParams);
            }

            if ( response.status === 200 ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                goToList();
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
        setLoadingSubmit(false);
    }

    const insTalentTarget = async() =>{
        setLoadingSubmit(true);
        try{
            let response = undefined;

            const targetData:any = {
                kTalent,
                anno: dialogAnno,
                target: dialogTarget,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'talent-target-ins',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                    ...targetData
                }
            }

            response = await axios(axiosParams);

            if ( response.status === 200 ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                getTalentTargets();
                handleCloseDialogTarget();
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
        setLoadingSubmit(false);
    }

    const modTalentTarget = async(params:any, oldRow:any) =>{
        setLoadingSubmit(true);
        try{
            let response = undefined;

            const targetData:any = {
                kTalent,
                anno: oldRow.ANNO,
                target: params.TARGET,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'talent-target-mod',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                    ...targetData
                }
            }

            response = await axios(axiosParams);

            if ( response.status === 200 ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                getTalentTargets();
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoadingSubmit(false);
            return oldRow;
        }
        setLoadingSubmit(false);
        return params;
    }

    const delTalentTarget = async(rowData:any) =>{
        setLoadingSubmit(true);
        try{
            let response = undefined;

            const targetData:any = {
                kTalent,
                anno: rowData.row.ANNO,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'talent-target-del',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                    ...targetData
                }
            }

            response = await axios(axiosParams);

            if ( response.status === 200 ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                getTalentTargets();
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
        setLoadingSubmit(false);
    }

	const toggleTalent = async() => {
        setLoadingSubmit(true);
		try{
            let response = undefined;

            const talentData = {
                kTalent,
                undo: dataCanc ? true : false,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'talent-del',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...talentData
                }
            }

            response = await axios(axiosParams);

            if(response.status === 200){
                NotificationManager.success(dataCanc ? 'Talent attivato' : 'Talent disattivato', '', 5000);
				goToList();
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
        setLoadingSubmit(false);
	}

    const handleOpenDialogTarget = () => {
        setOpenDialogTarget(true);
    }

    const handleCloseDialogTarget = () => {
        setDialogAnno("");
        setDialogTarget("");
        setOpenDialogTarget(false);
    }

    const goToList = () => {
        navigate('/talent');
    }

    if ( loading ) {
        return (
            <div className='component-container dettaglio-talent'>
                <CircleProgress/>
                <div className='component-card'/>
            </div>
        );
    } else {
        return (
            <div className='component-container dettaglio-talent'>
                {loadingSubmit && (<CircleProgress/>)}
                <div className='component-card'>
                    <div className='actions-wrapper'>
                        <IconButton title='Indietro' onClick={goToList}><ArrowLeft/></IconButton>
    				    <div className='wrapper-actions'>
                            { kTalent!=='new' && (
                                <IconButton title={dataCanc ? 'Attiva' : 'Disabilita'} onClick={toggleTalent}>
                                    {dataCanc ? <ActiveIcon/> : <DisabledIcon/>}
                                </IconButton>
                            )}
                            <IconButton title='salva' disabled={(nome === '' || cognome === '' || nomeArte === '' || email === '') || (esterno ? false : fee==='')} onClick={submitProcedure}><SaveIcon/></IconButton>
    				    </div>
                    </div>
                    <div className='infos-wrapper'>
                        <div className='flex-details-wrap'>
                            <div className='flex-rows-wrapper'>
                                <div className='flex-row'>
                                    <TextField 
                                        id="standard-basic" 
                                        label="Nome" 
                                        variant="standard"
                                        value={nome}
                                        onChange={(event) => setNome(event.target.value)}
                                    />
                                    <TextField 
                                        id="standard-basic" 
                                        label="Cognome" 
                                        variant="standard"
                                        value={cognome}
                                        onChange={(event) => setCognome(event.target.value)} 
                                    />
                                </div>
                                <div className='flex-row'>

                                    <TextField 
                                        id="standard-basic" 
                                        label="Nome d'arte" 
                                        variant="standard"
                                        value={nomeArte}
                                        onChange={(event) => setNomeArte(event.target.value)} 
                                    />

                                    <TextField 
                                        id="standard-basic" 
                                        label="Email" 
                                        variant="standard"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)} 
                                    />

                                </div>
                                <div className='flex-row'>
                                    <TextField 
                                        id="standard-basic" 
                                        label="% Fee Default" 
                                        variant="standard"
                                        value={ !!esterno ? '' : fee }
                                        onChange={(event) => {if(testNumberInputPercentages(event.target.value) || event.target.value === '') setFee(event.target.value)}}
                                        InputProps={{
                                            endAdornment: <PercentageIcon/>
                                        }}
                                        className='fixed-height-textField'
                                        disabled = { !!esterno }
                                    />
                                    <Autocomplete
                                        disablePortal
                                        id="areeTalent"
                                        options={areeTalentLista}
                                        value={areeTalent}
                                        multiple={true}
                                        onChange={(event:SyntheticEvent, newValue: any | null) =>{ setAreeTalent([ ...newValue ]) }}
                                        sx={{ width: 300 }}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Aree" variant="standard"/>
                                        }
                                        className={ areeTalent.length > 0 ? '' : 'fixed-height-autocomplete' }
                                    />
                                </div>
                                <div className='flex-row'>
                                    <FormGroup className='checkboxes-wrapper'>
                                        <FormControlLabel control={<Checkbox checked  = {!!esterno} 
                                                                            onChange = {(event) => {setEsterno(event.target.checked)}}
                                                                            //  disabled = {!!fee} 
                                                                            />} 
                                                        label="Talent Esterno" 
                                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        
                            {kTalent!=='new' && (<DataGridPremium
                                className='dataGrid'
                                slots={{
                                    noRowsOverlay: NoRowsDataGridComponent,
                                }}
                                initialState={{ pinnedColumns: { left: ['ACTION'], right: [] } }}
                                rows={targetRows}
                                columns={columns}
                                rowHeight={30}
                                hideFooter={true}
                                disableColumnMenu={false}
                                disableColumnReorder={true}
                                disableAggregation={true}
                                processRowUpdate={(params:any, oldRow:any)=>modTalentTarget(params, oldRow)}
                                onProcessRowUpdateError={(e) => console.log(e)}
                            />)}
                        </div>
    			        {kTalent!=='new' && (
                            <div className='flex-row dates-section'>
                                <TextField 
                                    id="dataIns" 
                                    label="Data Inserimento" 
                                    variant="standard"
                                    value={dataIns}
                                    disabled={true}
                                />
                                <TextField 
                                    id="dataMod" 
                                    label="Data Ultima Modifica" 
                                    variant="standard"
                                    value={dataMod}
                                    disabled={true}
                                />
                                <TextField 
                                    id="dataCanc" 
                                    label="Data Cancellazione" 
                                    variant="standard"
                                    value={dataCanc}
                                    disabled={true}
                                />
                            </div>
                        )}
    		        </div>
                </div>
                <Dialog open={ openDialogTarget } onClose={ handleCloseDialogTarget } className='dialog-ins-target'>
                    <DialogTitle className='dialogueTitleNuovoTarget'>
                        Nuovo Target
                    </DialogTitle>
                    <DialogContent className='flex-dialog-textfield'>
                    {loadingSubmit && <CircleProgress isDialog={true}/>}
                        <TextField 
                            id="standard-basic" 
                            label="Anno" 
                            variant="standard"
                            value={dialogAnno}
                            onChange={(event) => setDialogAnno(event.target.value)}
                            inputProps={{
                                maxLength: 4
                            }}
                        />
                        <TextField 
                            id="standard-basic" 
                            label="Target"
                            variant="standard"
                            value={dialogTarget}
                            onChange={(event) => {if(inputOnlyNumbers(event.target.value))setDialogTarget(event.target.value)}}
                        />
                    </DialogContent>
                    <DialogActions className='flex-box-between'>
                        <Button autoFocus onClick={ handleCloseDialogTarget }>Annulla</Button>
                        <Button onClick={ insTalentTarget }  variant='contained'>Conferma</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default TalentDettaglio