import React, { useState, createContext, useMemo, useEffect } from 'react';
import './App.css';
import config from './config.ts';
import { HashRouter, Routes, Route } from 'react-router-dom'
import SignIn from './routes/auth/SignIn.tsx';
import MainApp from './routes/application/index.tsx';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'split-pane-react/esm/themes/default.css';

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext';
import AppAuthContext from './contexts/AppAuthContext.tsx';
import ForgotPassword from './routes/auth/ForgotPassword.tsx';

const App: React.FunctionComponent = () => {
	
	return (
		<div className="app-main">
			<NotificationContainer/>
			<AuthProvider>	
				<AuthIsSignedIn>
					<AppAuthContext>
						<HashRouter>
							<Routes>
								<Route path='/*' element={<MainApp/>}/>
							</Routes>
						</HashRouter>
					</AppAuthContext>
				</AuthIsSignedIn>
				<AuthIsNotSignedIn>
					<HashRouter>
						<Routes>
							<Route path='/forgot-password' element={<ForgotPassword/>}/>
							<Route path='/*' element={<SignIn/>}/>
						</Routes>
					</HashRouter>
				</AuthIsNotSignedIn>
			</AuthProvider>
		</div>
	);
}

export default App;
