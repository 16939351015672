import React, {useState, useEffect, useContext, SyntheticEvent} from 'react';
import config from '../../../../config';
import { AuthContext } from '../../../../contexts/authContext';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import axios from 'axios';
import { ActiveIcon, ArrowLeft, DisabledIcon, PercentageIcon, SaveIcon, TrashIcon } from '../../../../assets/icons/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Autocomplete
        ,Button
        ,IconButton
        ,Dialog
        ,DialogActions
        ,DialogContent
        ,DialogTitle
        ,MenuItem
        ,TextField    } from '@mui/material';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import { AutocompleteLocationObject, AgenziaParams, UrlParamsObject, AutocompleteClientsAgentsObject } from '../../../../utils/ConfigInterfaces';
import { getFormatDateTime, testNumberInputPercentages, testNumberInputPercentagesTwoDecimals } from '../../../../utils/CommonUtilities';

function AgenziaDettaglio(props:any) {

    const { setPageName } = useContext(AppAuthGlobalVariables);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const urlParams:UrlParamsObject = useParams();
    const kAgenzia: number | string | undefined = urlParams?.id;
    const [ nome         , setNome           ] = useState("");
    const [ agenziaParent, setAgenziaParent  ] = useState<any>({ id: 0, label: '' });
    const [ nazione      , setNazione       ] = useState<AutocompleteLocationObject | null | undefined >({ id: 0, label: '' });
    const [ comune       , setComune         ] = useState<AutocompleteLocationObject | null>(null);
    const [ indirizzo    , setIndirizzo      ] = useState("");
    const [ cap          , setCap            ] = useState("");
    const [ localita     , setLocalita       ] = useState<any>("");
    const [ telefono     , setTelefono       ] = useState("");
    const [ email        , setEmail          ] = useState("");
    const [ pec          , setPec            ] = useState("");
    const [ dn           , setDn             ] = useState<string | number>(""); 
    const [ codiceSdi    , setCodiceSdi      ] = useState("");
    const [ codiceFiscale, setCodiceFiscale  ] = useState("");
    const [ partitaIva   , setPartitaIva     ] = useState("");
    const [ flagBypassCheckCF  , setflagBypassCheckCF   ] = useState("N");
    const [ flagBypassCheckPiva, setflagBypassCheckPiva ] = useState("N");
    const [ dataIns , setDataIns  ] = useState('');
    const [ dataMod , setDataMod  ] = useState('');
    const [ dataCanc, setDataCanc ] = useState('');
    
    const [ agenzieLista, setAgenzieLista   ] = useState<any>(null);
    const [ comuni      , setComuni         ] = useState<any>(null);
    const [ nations     , setNations        ] = useState<any>(null);

    const [ inputComuni , setInputComuni    ] = useState<any>('');
    
    const [ onReload     , setOnReload      ] = useState<boolean | null>(null);
    const [ loading      , setLoading       ] = useState(true); 
    const [ openDialog   , setOpenDialog    ] = useState<boolean | string>(false);
    const [ dialogContent, setDialogContent ] = useState<any>({ err: '' , desc: '' });

    const requiredFieldsFilled:boolean = (nome === '' || !nazione?.id || (nazione.id===121 ? !comune?.id : false));

	const handleChangeNazione = (nationSelected: AutocompleteLocationObject) => {
		setNazione({id: nationSelected?.id, label: nationSelected?.label});
		if(nationSelected?.label !== "ITALIA"){
			setComune({ id: 0, label: '' });
		}
	}

    const handleChangeCF = (value: string) => {
        setflagBypassCheckCF('N');
        setCodiceFiscale(value);
    }
    const handleChangePIva = (value: string) => {
        setflagBypassCheckPiva('N');
        setPartitaIva(value);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogContent({});
    }

    const handleSubmitByPass = () => {
        if ( openDialog === 'Partita Iva' ){
            setflagBypassCheckPiva('Y')
        } else if (openDialog === 'Codice Fiscale') {
            setflagBypassCheckCF('Y')
        }
    }

    useEffect(() => {
        async function init(){
            let savedNations:any = localStorage.getItem('nazioni');
		    let savedComuni:any = localStorage.getItem('comuni');

            setNations(JSON.parse(savedNations));
            setComuni(JSON.parse(savedComuni));
            await getAgenzie(true);
            if ( kAgenzia !== 'new' ){ 
                await getAgenzie(false);
                setPageName?.('Agenzia - ' + urlParams.id)
            }else{
                setPageName?.('Nuova Agenzia');
            }
            setOnReload(!onReload);
        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(() => {
        if ( onReload !== null ) {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect(() => {
        if ((( openDialog === 'Codice Fiscale') && ( flagBypassCheckCF === 'Y' )) || (( openDialog === 'Partita Iva' ) && ( flagBypassCheckPiva === 'Y' ))) {
            setLoading(true);
            handleCloseDialog();
            submitProcedure();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ flagBypassCheckCF, flagBypassCheckPiva ])

    const getAgenzie = async (allAgents:boolean) => {
        try{
            let axiosParams = {
                url: 'agenzie',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {}
            }

            if(!allAgents){
                axiosParams.params = { kAgenzia }
            }else if(kAgenzia !== 'new'){
                axiosParams.params = { 
                    kAgenzia,
                    filterSelfAgent: true
                }
            }
        
            let response = await axios(axiosParams);
    
            if(response.status === 200){
                if(!allAgents){
                    let data = response.data[0];
                    setNome(data.DAGENZIA);
                    setAgenziaParent({ id: data.KAGENZIA_PARENT, label: data.DAGENZIA_PARENT });
                    setIndirizzo(data.INDIRIZZO);
                    setCap(data.CAP);
                    setLocalita(data.LOCALITA);
                    setNazione({ id: data.KNAZIONE, label: data.DNAZIONE });
                    setComune({id: data.KCOMUNE, label: data.DCOMUNE});
                    setTelefono(data.TELEFONO);
                    setEmail(data.E_MAIL);
                    setPec(data.PEC_MAIL);
                    let dnReplace = ((data.DN_PERC || '')+'')?.replace('.', ',');
                    setDn(dnReplace);
                    setCodiceFiscale(data.CODICE_FISCALE);
                    setPartitaIva(data.PARTITA_IVA);
                    setCodiceSdi(data.CODICE_SDI);
                    setDataIns(getFormatDateTime(data.DATA_INS));
                    setDataMod(getFormatDateTime(data.DATA_ULTIMA_MOD));
                    setDataCanc(getFormatDateTime(data.DATA_CANC));
                }else{
                    setAgenzieLista([...response.data, { id: 0, label: '' } ]);
                }
            }
        }catch (e:any){
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    const submitProcedure = async () => {
	    setLoading(true);
        try{
            let response = undefined;

            const agentData:any = {
                dAgenzia: nome,
                kAgenziaParent  : agenziaParent?.id,
                kNazione              : nazione?.id,
                kComune                : comune?.id,
                indirizzo,
                cap,
                localita,
                telefono,
                email,
                pec,
                codiceFiscale, 
                partitaIva,
                sdi                     : codiceSdi,
                dn: (dn+'')?.replace(',', '.'),
                flagBypassCheckCF,
                flagBypassCheckPiva,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...agentData
                }
            }
            
            if(kAgenzia === 'new'){
                axiosParams.url = 'agenzia-ins';
                response = await axios(axiosParams);
            }else{
                axiosParams.url = 'agenzia-mod';
                axiosParams.data.kAgenzia = kAgenzia;
                response = await axios(axiosParams);
            }

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ){
                NotificationManager.success('Procedura Completata', '', 5000);
                goToList();
            } else if ( response.status === 200 && nRetVal === -8 ) {
                setOpenDialog('Codice Fiscale');
                setDialogContent({ err: errorMessage });
            } else if ( response.status === 200 && nRetVal === -10 ) {
                setOpenDialog('Partita Iva');
                setDialogContent({ err: errorMessage });
            } else {
                NotificationManager.error(JSON.stringify(errorMessage), 'Errore', 10000)
            }
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }    
        setOnReload(!onReload);
    }

    const toggleAgenzia = async () => {
	    setLoading(true);
        try{
            let response = undefined;

            const agentData = {
                kAgenzia,
                undo: dataCanc ? true : false,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url     : 'agenzia-del',
                method  : 'post',
                baseURL : config.API_URL,
                headers : {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data    : {
                  ...agentData
                }
            }
            
            response = await axios(axiosParams);
           
            if ( response.status === 200 ){
                NotificationManager.success(dataCanc ? 'Agenzia attivata' : 'Agenzia disattivata')
                goToList();
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
        setOnReload(!onReload);
    }

    const goToList = () => {
        navigate('/agenzie');
    }
    
    if(loading){
        return (
            <div className='component-container dettaglio-agenzia'>
                <CircleProgress/>
                <div className='component-card'/>
            </div>);
    }else{
        return (
            <div className='component-container dettaglio-agenzia'>
                <div className='component-card'>
                    <div className='actions-wrapper'>
                        <IconButton title='Indietro' onClick={goToList}><ArrowLeft/></IconButton>
                        <div className='wrapper-actions'>
                            {kAgenzia!=='new' && (<IconButton title={dataCanc ? 'Attiva' : 'Disabilita'} onClick={toggleAgenzia}>{dataCanc ? <ActiveIcon/> : <DisabledIcon/>}</IconButton>)}
                            <IconButton title='Salva' disabled={requiredFieldsFilled} onClick={submitProcedure}><SaveIcon/></IconButton>
                        </div>
                    </div>
                    <div className='infos-wrapper'>
                        <div className='flex-row'>
                            <TextField 
                                id="standard-basic" 
                                label="Nome" 
                                variant="standard"
                                value={nome}
                                onChange={(event) => setNome(event.target.value)}
                                inputProps={{maxLength: 50}}
                            />

                            <Autocomplete
                                disablePortal
                                id="agenziaParent"
                                options={agenzieLista}
                                value={agenzieLista.find((az:any) => az.id === agenziaParent.id)}
                                multiple={false}
                                onChange={(event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null) => setAgenziaParent({id: newValue?.id, label: newValue?.label || ''})}
                                sx={{ width: 300 }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Agenzia Padre" variant="standard"/>
                                }
                            />

                            <TextField 
                                id="standard-basic" 
                                label="DN" 
                                variant="standard"
                                value={dn}
                                error={(dn || dn === 0) ? !testNumberInputPercentagesTwoDecimals(dn+'') : dn === '' ? false : true} 
                                onChange={(event) => {setDn(event.target.value)}}
                                inputProps={{ maxLength: 50 }}
                                InputProps={{ endAdornment: <PercentageIcon/> }}
                            />
                        </div>
                        <div className='flex-row'>

                            <Autocomplete
                                disablePortal
                                id="nazioni"
                                options={nations}
                                value={nations.find((nz:any) => nz.id === nazione?.id)}
                                multiple={false}
                                onChange={(event:SyntheticEvent, newValue: AutocompleteLocationObject) => {handleChangeNazione(newValue)}}
                                sx={{ width: 300 }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Nazione" variant="standard"/>
                                }
                            />

                            <Autocomplete
                                disablePortal
                                id="comuni"
                                options={comuni}
                                value={comuni.find((nz:any) => nz.id === comune?.id)}
                                multiple={false}
                                disabled={nazione?.id !== 121}
                                onChange={(event:SyntheticEvent, newValue: AutocompleteLocationObject | null) => setComune({id: newValue?.id, label: newValue?.label})}
                                sx={{ width: 300 }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Comune" variant="standard"/>
                                }
                                filterOptions={(options, state) => {
                                    if (state.inputValue.length > 1) {
                                    return options.filter((item) =>
                                        String(item.label).toLowerCase().includes(state.inputValue.toLowerCase())
                                    );
                                    }
                                    return [];
                                }}
                                noOptionsText={inputComuni.length < 2 ? 'Digitare almeno 2 caratteri' : 'Nessun Riscontro'}
                                onInputChange={(event:any, value:string) => {setInputComuni(value)}}
                            />

                            <TextField 
                                id="localita" 
                                label="Località" 
                                variant="standard"
                                value={localita}
                                onChange={(event) => setLocalita(event.target.value)} 
                                inputProps={{maxLength: 50}}
                            />
                        </div>
                        <div className='flex-row'>
                            <TextField 
                                id="indirizzo" 
                                label="Indirizzo" 
                                variant="standard"
                                value={indirizzo}
                                onChange={(event) => setIndirizzo(event.target.value)} 
                                inputProps={{maxLength: 100}}
                            />
                            <TextField 
                                id="cap" 
                                label="Cap" 
                                variant="standard"
                                value={cap}
                                onChange={(event) => setCap(event.target.value)} 
                                inputProps={{maxLength: 20}}
                            />

                        </div>
                        <div className='flex-row'>
                            <TextField 
                                id="telefono" 
                                label="Telefono" 
                                variant="standard"
                                value={telefono}
                                onChange={(event) => setTelefono(event.target.value)}
                                inputProps={{maxLength: 20}}
                            />
                            <TextField 
                                id="email" 
                                label="Email Amministrativa" 
                                variant="standard"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)} 
                                inputProps={{maxLength: 50}}
                            />
                            <TextField 
                                id="pec" 
                                label="Pec" 
                                variant="standard"
                                value={pec}
                                onChange={(event) => setPec(event.target.value)} 
                                inputProps={{maxLength: 50}}
                            />
                        </div>
                        <div className='flex-row'>
                            <TextField 
                                id="codiceFiscale" 
                                label="Codice Fiscale" 
                                variant="standard"
                                value={codiceFiscale}
                                onChange={(event) => handleChangeCF(event.target.value)}
                                inputProps={{maxLength: 20}}
                            />
                            <TextField 
                                id="partitaIva" 
                                label="Partita Iva" 
                                variant="standard"
                                value={partitaIva}
                                onChange={(event) => handleChangePIva(event.target.value)}
                                inputProps={{maxLength: 20}}
                            />
                            <TextField 
                                id="codiceSdi" 
                                label="Codice SDI" 
                                variant="standard"
                                value={codiceSdi}
                                onChange={(event) => setCodiceSdi(event.target.value)}
                                inputProps={{maxLength: 10}}
                            />
                        </div>
                 
                        {kAgenzia!=='new'&&(
                            <div className='flex-row dates-section'>
                                <TextField 
                                    id="dataIns" 
                                    label="Data Inserimento" 
                                    variant="standard"
                                    value={dataIns}
                                    disabled={true}
                                />
                                <TextField 
                                    id="dataMod" 
                                    label="Data Ultima Modifica" 
                                    variant="standard"
                                    value={dataMod}
                                    disabled={true}
                                />
                                <TextField 
                                    id="dataCanc" 
                                    label="Data Cancellazione" 
                                    variant="standard"
                                    value={dataCanc}
                                    disabled={true}
                                />
                            </div>)
                        }

                    </div>
                    <Dialog open={ !!openDialog } onClose={ handleCloseDialog }>
                        <DialogTitle className='dialogueTitleAreeContainer'>
                            { dialogContent.err }
                        </DialogTitle>
                        <DialogContent className='align-textifieldBypass-center'>
                            <span>{
                                `Stai provando ad inserire ${ openDialog === 'Partita Iva' ? 'una' : 'un' } ${ dialogContent.err }`
                            }</span>
                            <br/><br/>
                            <span>{
                                'Desideri procedere lo stesso?'
                            }</span>
                        </DialogContent>
                        <DialogActions className='flex-box-between'>
                            <Button onClick={ handleCloseDialog  } autoFocus>Annulla</Button>
                            <Button onClick={ handleSubmitByPass } variant='contained'>Conferma</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
};

export default AgenziaDettaglio;