import React from 'react'

function Page500() {
	return (
		<div className='component-container dashboard'>
			<div className='component-card'>
				Page500
			</div>
		</div>
	)
}

export default Page500