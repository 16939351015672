function SySbackground() {
  return (
    <> 
        <div className="background-with-logo"></div>
        <div className="background-with-logo-top-dx"></div>
        <div className="background-with-logo-top-dx-bis"></div>
        <div className="background-with-logo-bot-dx"></div>
        <div className="background-with-logo-bot-dx-bis"></div>
    </>
  )
}

export default SySbackground