import React, { useContext, useState } from 'react';
import './SignIn.css';
import config from '../../config';
import { inputOnlyNumbers } from '../../utils/CommonUtilities';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import SySbackground from '../../components/SySbackground/SySbackground';
import CircleProgress from '../../components/CircleProgress/CircleProgress';
import DoomLogo from '../../assets/svgs/DOOM-logo.svg';
import sysLogo from '../../assets/svgs/systrategy_logo.svg';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function SignIn() {

	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [signinLoading, setSigninLoading] = useState(false);
	const [openDialogMFA, setOpenDialogMFA] = useState(false);
	const [codeTOTP, setCodeTOTP] = useState('');

	const signIn = async(event:any) => {
		event.preventDefault();
		setSigninLoading(true);
		try{
			let response = await auth.signInWithEmail(email, password);
			if(response === 'SOFTWARE_TOKEN_MFA'){
				setOpenDialogMFA(true);
				setSigninLoading(false);
			}else{
				navigate('/dashboard');
				setSigninLoading(false);
			}
		}catch(e:unknown){
			if(e instanceof Error){
				setSigninLoading(false);
				//notifica di credenziali errate diretta nel caso in cui l'mfa non sia obbligatorio
				NotificationManager.error('Credenziali Errate', 'Login Fallito', config.DEFAULT_TIMEOUT_POPUPS)
			}else{
				setSigninLoading(false);
				//altrimenti forzo lo stesso il popup anche da credenziali errate
				setOpenDialogMFA(true);
			}
		}
	}

	const btnEnabled:boolean = (email !== '' && password !== '');

	const handleCloseDialog = () => {
		setCodeTOTP('');
		setOpenDialogMFA(false);
	}

	const submitTOTP = async(event:any = false) => {
		if(event)event.preventDefault();
		setSigninLoading(true);
		handleCloseDialog();
		try{
			await auth.signInWithTOTP(codeTOTP, email, password);
			setSigninLoading(false);
			navigate('/dashboard');
		}catch(e:any){
			setSigninLoading(false);
			NotificationManager.error('Credenziali Errate', 'Login Fallito', config.DEFAULT_TIMEOUT_POPUPS);
		}
	}

	return (
		<div className='signin'>
			{signinLoading && (<CircleProgress isDialog={true}/>)}
			<div className="signin-outer-container">
				<SySbackground/>
				<div id="signin-form" className="signin-container">
					<div className="side left signin-content">
						<div className="app-login-header">
							<span className="login-media">Business</span>
							<span className="login-anal">Manager</span>	
						</div>
						<div className="user-psw-container">
							<form onSubmit={ (event:React.FormEvent<HTMLFormElement>) =>signIn(event) }>
								<div className="">
									<FormControl className="signin-credentials-wrapper top">
										<Input
											name            ="email"
											value           ={ email }
											placeholder     ="Email"
											type			="text"
											spellCheck      ={ false }
											onChange        ={ e => { setEmail(e.target.value); } }
											startAdornment  = {
												<InputAdornment position="start">
													<AccountCircleIcon />
												</InputAdornment>
											}
										/>
									</FormControl>
								</div>
					
								<div className="">
									<FormControl className="signin-credentials-wrapper">
										<Input
											name            ="password"
											id              ="password"
											placeholder     ="Password"
											type            ={ showPassword ? 'text' : 'password' }
											value           ={ password }
											onChange        ={ e => { setPassword(e.target.value); } }
											spellCheck      ={ false }
											startAdornment  ={
												<InputAdornment position="start">
													<KeyIcon />
												</InputAdornment>
											}
											endAdornment    ={
												<InputAdornment position="end">
													<IconButton
														aria-label ="toggle password visibility"
														onClick    ={ e => { setShowPassword(!showPassword); } }
													>
														{ showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/> }
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</div>
						
								<div className="button-wrapper">
									<Button
										variant     = "outlined"
										type='submit'
										className   ={ "text-uppercase signin-btn" }
										disabled    ={ !btnEnabled }
									>LOGIN</Button>
									<div
										
										className   ={ "text-uppercase forgot-password-label" }
										onClick     ={ ()=>navigate('/forgot-password') }
									>Password Dimenticata?</div>
								</div>
							</form>
						</div>
						<div className="footer-info top-bottom-section">
							<a href="https://www.systrategy.it" target="_blank" rel="noreferrer">
								<img
									src         ={sysLogo}
									alt         ="Systrategy"
									draggable   ="false"
									className   ="systrategy-logo"
								/>
							</a>
						</div>
					</div>
					<div className='side right only-logo'>
						<img draggable="false" className='logo-lg' src={DoomLogo} alt="CRM Analyzer"/>
					</div>
				</div>
			</div>
			<Dialog open={openDialogMFA} onClose={handleCloseDialog}>
				<DialogTitle>Multi-Factor Authentication</DialogTitle>
				<DialogContent className='align-mfa-center'>
					<DialogContentText className='font-12'>
						Open your authentication app and enter a MFA code to complete sign-in
					</DialogContentText>
					<form onSubmit={(event:React.FormEvent<HTMLFormElement>) =>submitTOTP(event)}>
						<FormControl className="signin-credentials-wrapper">
							<OutlinedInput
								className       ="center-text font-24"
								autoComplete='off'
								name            ="totp"
								id              ="totp"
								placeholder     ="Code"
								type            ='text'
								inputProps		={{maxLength: 6}}
								value           ={ codeTOTP }
								onChange        ={ e => { if(inputOnlyNumbers(e.target.value))setCodeTOTP(e.target.value); } }
								spellCheck      ={ false }
								autoFocus
							/>
						</FormControl>
					</form>
				</DialogContent>
				<DialogActions className='flex-box-between'>
					<Button onClick={handleCloseDialog}>Annulla</Button>
					<Button onClick={submitTOTP} variant='contained'>Conferma</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default SignIn;