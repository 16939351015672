import React, { SyntheticEvent, useEffect, useState, useContext } from 'react'
import { Autocomplete, Button
    ,Checkbox
    ,Dialog
    ,DialogActions
    ,DialogContent
    ,DialogContentText
    ,DialogTitle
    ,IconButton
    ,InputLabel
    ,TextField } from '@mui/material';
import { PercentageIcon } from '../../../../../assets/icons/icons';
import CircleProgress                       from '../../../../../components/CircleProgress/CircleProgress';
import axios from 'axios';
import { AgenziaParams, AutocompleteClientsAgentsObject, AutocompleteLocationObject } from '../../../../../utils/ConfigInterfaces';
import config from '../../../../../config';
import { AuthContext } from '../../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { testNumberInputPercentages } from '../../../../../utils/CommonUtilities';

function AddEntryDialog(props:any) {

    const auth = useContext(AuthContext);

    const [kListaParent, setKListaParent] = useState<any>(null);
    const [nome     , setNome] = useState('')
    const [cognome  , setCognome] = useState('');

    const [nazione  , setNazione] = useState<AutocompleteLocationObject | null>(null);
    const [comune   , setComune] = useState<AutocompleteLocationObject | null>(null);

    const [ inputComuni , setInputComuni    ] = useState<any>('');



    const [localita , setLocalita] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [cap      , setCap] = useState('');
    const [telefono , setTelefono] = useState('');
    const [email    , setEmail] = useState('');
    const [pec      , setPec] = useState('');
    const [codiceSdi, setCodiceSdi] = useState('');
    const [dn       , setDn] = useState(""); 
    const [codiceFiscale, setCodiceFiscale] = useState('');
    const [partitaIva   , setPartitaIva] = useState('');
    const [ flagBypassCheckCF   , setflagBypassCheckCF   ] = useState("N");
    const [ flagBypassCheckPiva , setflagBypassCheckPiva ] = useState("N");

    const [ openDialog    ,setOpenDialog    ] = useState<boolean | string>(false);
    const [ dialogContent ,setDialogContent ] = useState<any>({ err: '' , desc: '' });
    const [ loading       , setLoading      ] = useState(false); 

    

    const disableSubmitCondition = props.type === 'Referente Progetto' 
                                   ?
                                   nome === '' || cognome === '' || email === ''
                                   :
                                       nome === '' || !nazione?.id || (nazione.id===121 ? !comune?.id : false)

    const handleChangeNazione = (nationSelected: AutocompleteLocationObject) => {
        setNazione(nationSelected);
        if(nationSelected.label !== "ITALIA"){
            setComune(null);
        }
    }

    const handleChangeCF = (value: string) => {
        setflagBypassCheckCF('N');
        setCodiceFiscale(value);
    }

    const handleChangePIva = (value: string) => {
        setflagBypassCheckPiva('N');
        setPartitaIva(value);
    }

    const handleSubmitByPass = () => {
        if ( openDialog === 'Partita Iva' ){
            setflagBypassCheckPiva('Y')
        } else if (openDialog === 'Codice Fiscale') {
            setflagBypassCheckCF('Y')
        }
    }

    const submitIns = async () => {
        setLoading(true);
        try{
            let response = undefined;

            const datas:any = {
                kNazione: nazione?.id,
                kComune: comune?.id,
                indirizzo,
                cap,
                localita,
                telefono,
                email,
                pec,
                codiceFiscale, 
                partitaIva,
                sdi: codiceSdi,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {}
            }

            if(props.type === 'Agenzia'){
                axiosParams.url = 'agenzia-ins';
                axiosParams.data = {
                    ...datas,
                    dAgenzia: nome,
                    kAgenziaParent: kListaParent?.id,
                    dn,
                    flagBypassCheckCF, // : 'Y',
                    flagBypassCheckPiva, // : 'Y',
                }
            }else if(props.type === 'Cliente'){
                axiosParams.url = 'cliente-ins';
                axiosParams.data = {
                    ...datas,
                    dCliente: nome,
                    kClienteParent: kListaParent?.id,                                        
                   
                    flagBypassCheckCF, // : 'Y',
                    flagBypassCheckPiva, // : 'Y',
                }
            }else if(props.type === 'Referente Progetto'){
                axiosParams.url = 'ref-progetto-ins';
                axiosParams.data = {
                    ...datas,
                    dRPCognome: cognome,
                    dRPNome: nome,
                    dRPEmail: email
                }
            }

            response = await axios(axiosParams);
            let { nRetVal, errorMessage } = response.data;
            //Serve per autotargettare cliente ed agenzia post ins
            let resIns = undefined;
            if( response.status === 200 && nRetVal > 0 && !errorMessage ){
                NotificationManager.success('Procedura Completata', '', 5000);
                if(props.type === 'Agenzia'){
                    resIns = await props.getAgenzie();
                    if(props.kPropStatus === '01' || props.kPropStatus === '02' || props.kPropStatus === '03' || props.kPropStatus === '07'){
                        let foundNewAgi = resIns.find((el:any) => el.id === nRetVal);
                        props.handleChange('agenzia', foundNewAgi);
                    }
                }else if(props.type === 'Cliente'){
                    resIns = await props.getClienti();
                    if(props.kPropStatus === '01' || props.kPropStatus === '02' || props.kPropStatus === '03' || props.kPropStatus === '07'){
                        let foundNewCli = resIns.find((el:any) => el.id === nRetVal);
                        props.handleChange('cliente', foundNewCli);
                    }
                }else if(props.type === 'Referente Progetto'){
                    await props.getReferentiProgetto();
                }
                closeDialog();
            } else if ( response.status === 200 && nRetVal === -8 ) {
                setOpenDialog('Codice Fiscale');
                setDialogContent({ err: errorMessage });
            } else if ( response.status === 200 && nRetVal === -10 ) {
                setOpenDialog('Partita Iva');
                setDialogContent({ err: errorMessage });
            }
        }catch(e:any){
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if ((( openDialog === 'Codice Fiscale') && ( flagBypassCheckCF === 'Y' )) || (( openDialog === 'Partita Iva' ) && ( flagBypassCheckPiva === 'Y' ))) {
            submitIns();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ flagBypassCheckCF, flagBypassCheckPiva ]);

    const closeDialog = () => {
        setKListaParent(null);
        setNome('');
        setCognome('');
        setNazione(null);
        setComune(null);
        
        setLocalita('');
        setIndirizzo('');
        setCap('');
        setTelefono('');
        setEmail('');
        setPec('');
        setCodiceFiscale('');
        setPartitaIva('');
        setCodiceSdi('');
        
        setflagBypassCheckCF('N');
        setflagBypassCheckPiva('N');
        props.handleCloseDialog();
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogContent({});
    }

    const byPassDialogue = () => {
        return (
            <Dialog open={ !!openDialog } onClose={ handleCloseDialog }>
                <DialogTitle className='dialogueTitleAreeContainer'>
                    { dialogContent.err }
                </DialogTitle>
                <DialogContent className='align-textifieldBypass-center'>
                    <span>{
                        `Stai provando ad inserire ${ openDialog === 'Partita Iva' ? 'una' : 'un' } ${ dialogContent.err }`
                    }</span>
                    <br/><br/>
                    <span>{
                        'Desideri procedere lo stesso?'
                    }</span>
                </DialogContent>
                <DialogActions className='flex-box-between'>
                    <Button onClick={ handleCloseDialog } autoFocus>Annulla</Button>
                    <Button onClick={ handleSubmitByPass } variant='contained'>Conferma</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open={ props.openDialog } onClose={ closeDialog } className='add-entry-dialog'>
            <DialogTitle className='dialogueTitleAreeContainer'>
                {props.type === 'Agenzia' ? 'Nuova' : 'Nuovo'} {props.type}
            </DialogTitle>
            <DialogContent className='align-textifieldAree-center'>
                { loading && ( <CircleProgress isDialog={true}/> )}
                <div className='infos-wrapper'>
                    <div className='flex-row'> 
                        <TextField 
                            id="standard-basic" 
                            label="Nome" 
                            variant="standard"
                            value={nome}
                            onChange={(event) => setNome(event.target.value)}
                            inputProps={{maxLength: 50}}
                        />

                        {props.type==='Referente Progetto'&&(
                            <TextField 
                                id="standard-basic" 
                                label="Cognome" 
                                variant="standard"
                                value={cognome}
                                onChange={(event) => setCognome(event.target.value)}
                                inputProps={{maxLength: 50}}
                            />
                        )}
                        {(props.type==='Agenzia' || props.type==='Cliente') &&(
                            <Autocomplete
                                disablePortal
                                id="parent-element"
                                options={props.listaParent}
                                value={props.listaParent.find((az:any) => az.id === kListaParent)}
                                multiple={false}
                                onChange={(event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null) => {return setKListaParent({id: newValue?.id, label: newValue?.label})}}
                                sx={{ width: 300 }}
                                renderInput={(params) =>
                                    <TextField {...params} label={`${props.type} Padre`} variant="standard"/>
                                }
                            />
                        )}
                    </div>
                    {(props.type==='Agenzia' || props.type==='Cliente') &&(
                        <>
                            <div className='flex-row'>
                                <Autocomplete
                                    disablePortal
                                    id="nazioni"
                                    options={props.nations}
                                    value={(props.nations || []).find((nz:any) => nz.id === nazione?.id)}
                                    multiple={false}
                                    onChange={(event:SyntheticEvent, newValue: AutocompleteLocationObject | null) => handleChangeNazione({id: newValue?.id, label: newValue?.label})}
                                    sx={{ width: 300 }}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Nazione" variant="standard"/>
                                    }
                                />

                                <Autocomplete
                                    disablePortal
                                    id="comuni"
                                    options={props.comuni}
                                    value={((props.comuni).find((nz:any) => nz.id === comune?.id)) || null}
                                    multiple={false}
                                    disabled={nazione?.label !== 'ITALIA'}
                                    onChange={(event:SyntheticEvent, newValue: AutocompleteLocationObject | null) => setComune({id: newValue?.id, label: newValue?.label})}
                                    sx={{ width: 300 }}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Comune" variant="standard"/>
                                    }
                                    filterOptions={(options, state) => {
                                        if (state.inputValue.length > 1) {
                                            return options.filter((item) =>
                                                String(item.label).toLowerCase().includes(state.inputValue.toLowerCase())
                                            );
                                        }
                                        return [];
                                    }}
                                    noOptionsText={inputComuni.length < 2 ? 'Digitare almeno 2 caratteri' : 'Nessun Riscontro'}
                                    onInputChange={(event:any, value:string) => {setInputComuni(value)}}
                                />
                            </div>
                            <div className='flex-row'>
                                <TextField 
                                    id="localita" 
                                    label="Località" 
                                    variant="standard"
                                    value={localita}
                                    onChange={(event) => setLocalita(event.target.value)} 
                                    inputProps={{maxLength: 50}}
                                />

                                <TextField 
                                    id="indirizzo" 
                                    label="Indirizzo" 
                                    variant="standard"
                                    value={indirizzo}
                                    onChange={(event) => setIndirizzo(event.target.value)} 
                                    inputProps={{maxLength: 100}}
                                />
                            </div>
                            <div className='flex-row'>
                                <TextField 
                                    id="cap" 
                                    label="Cap" 
                                    variant="standard"
                                    value={cap}
                                    onChange={(event) => setCap(event.target.value)} 
                                    inputProps={{maxLength: 20}}
                                />
                            </div>
                            <div className='flex-row'>
                                <TextField 
                                    id="telefono" 
                                    label="Telefono" 
                                    variant="standard"
                                    value={telefono}
                                    onChange={(event) => setTelefono(event.target.value)}
                                    inputProps={{maxLength: 20}}
                                />
                            </div>
                        </>
                    )}
                    
                        <div className='flex-row'>
                            <TextField 
                                id="email" 
                                label="Email Amministrativa" 
                                variant="standard"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)} 
                                inputProps={{maxLength: 50}}
                            />
                            {(props.type==='Agenzia' || props.type==='Cliente') &&(
                                <TextField 
                                    id="pec" 
                                    label="Pec" 
                                    variant="standard"
                                    value={pec}
                                    onChange={(event) => setPec(event.target.value)} 
                                    inputProps={{maxLength: 50}}
                                />
                            )}
                        </div>

                    {(props.type==='Agenzia' || props.type==='Cliente') &&(
                        <div className='flex-row'>
                            <TextField 
                                id="codiceFiscale" 
                                label="Codice Fiscale" 
                                variant="standard"
                                value={codiceFiscale}
                                onChange={(event) => handleChangeCF(event.target.value)}
                                inputProps={{maxLength: 20}}
                            />

                            <TextField 
                                id="partitaIva" 
                                label="Partita Iva" 
                                variant="standard"
                                value={partitaIva}
                                onChange={(event) => handleChangePIva(event.target.value)}
                                inputProps={{maxLength: 20}}
                            />
                        </div>
                    )}
                    {(props.type==='Agenzia' || props.type==='Cliente') &&(
                        <div className='flex-row'>
                        
                            <TextField 
                                id="codiceSdi" 
                                label="Codice SDI" 
                                variant="standard"
                                value={codiceSdi}
                                onChange={(event) => setCodiceSdi(event.target.value)}
                                inputProps={{maxLength: 10}}
                            />
                            {props.type==='Agenzia'&&(
                                <TextField 
                                    id="standard-basic" 
                                    label="DN" 
                                    variant="standard"
                                    value={dn}
                                    onChange={(event) => {if(testNumberInputPercentages(event.target.value) || event.target.value === '') {setDn(event.target.value)}}}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{ endAdornment: <PercentageIcon/> }}
                                />
                            )}
                        </div>
                    )}
                </div>
                { byPassDialogue() }
            </DialogContent>
            <DialogActions className='flex-box-between'>
                <Button onClick={ closeDialog } autoFocus>Annulla</Button>
                <Button onClick={ submitIns } disabled={ disableSubmitCondition } variant='contained'>Conferma</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEntryDialog