
import React, { useState, useEffect, useContext } from 'react'
import QRCode from "react-qr-code";
import { Button }                 from '@mui/material';
import { NotificationManager }    from 'react-notifications';
import { useNavigate, useParams } from 'react-router-dom';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { AuthContext }            from '../../../../contexts/authContext.tsx';
import { inputOnlyNumbers }       from '../../../../utils/CommonUtilities';
import CircleProgress             from '../../../../components/CircleProgress/CircleProgress';
import './MfaSettings.css';

function ConfigMFA(props: any) {
    const { setPageName } = useContext(AppAuthGlobalVariables);
    const urlParams = useParams();

    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const [stringQr, setStringQr] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [isSecretToReveal, setIsSecretToReveal] = useState(false);
    const [confirmTotpCode, setConfirmToptCode] = useState('');
    const [step, setStep] = useState(1);
    const [showQrCode, setShowQrCode] = useState(false);

    useEffect(() => {
        setPageName?.(urlParams['*']);

        async function init(){
            try{
                let response = await auth.requestTokenTOTP();

                setStringQr(response?.qrCodeUrl);
                setSecretCode(response?.secretCode);

            } catch(error: any) {
                //questi parametri sono importanti in ogni parte autenticata dell'applicazione quindi nel caso andasse in errore l'utente DEVE rifare il login
                if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error('Non Autorizzato', 'Errore' /* <FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>*/, 10000);
                }else if(error.response.status === 500){
                    NotificationManager.error('Servizio non disponibile', 'Errore' /* <FormattedMessage id={'notificationManager.connectionError.text.postLogin'}/>, <FormattedMessage id={'notificationManager.connectionError.text'}/>*/, 10000);
                }else{
                    NotificationManager.error('Errore imprevisto', 'Errore' /*<FormattedMessage id={'notificationManager.connectionError.text.postLogin'}/>, <FormattedMessage id={'notificationManager.connectionError.text'}/>*/, 10000);
                }
                signOut();
            }
            setLoading(false)
        }
        init();
    },[]);

    const signOut = () => {
        auth.signOut();
        navigate('/signin');
    }

    const submitTotp = async(event:any) => {
        event.preventDefault();
        setLoading(true);
        try{
            let response = await auth.verifySoftwareTOTP(confirmTotpCode);

            if(response.Status==='SUCCESS'){
                attivazioneMFA();
            }
        }catch(e:any){
            setLoading(false);
            NotificationManager.error('Codice Errato', '', 10000);
        }
    }

    const attivazioneMFA = async() => {
        try{
            //probabilmente il remember qua non serve
            await auth.rememberDevice();
            await auth.enableMFA();
            // NotificationManager.success(/*<FormattedMessage id={'MFA.successActivation.text'}/>, <FormattedMessage id={'MFA.successActivation.title'}/>*/, 5000);
            //setTimeout(() => {
            localStorage.removeItem('isMFAToConfig');
            navigate('/dashboard'); // !!! || window.location.reload();
            //},1000);
        }catch(e:any){
            setLoading(false);
            NotificationManager.error('Errore nell\'attivazione del Multi-Factor-Authenthication', 'Errore', 10000);
        }
    }

    return (
        <div className='component-container configMFA'>
            {loading &&( <CircleProgress isDialog={true}/> )}
            <div className='component-card'>
                <div className='steps-box'>
                    {
                        step === 1 ?
                            <div className='first-step'>
                                <div>Configurazione dell'autenticazione a più fattori</div> {/* <div><FormattedMessage id="MFA.firstStep.title"/></div> */}
                                <div>Gentile utente, per migliorare la sicurezza del tuo account, completa la configurazione del codice MFA.</div>{/* <div><FormattedMessage id="MFA.firstStep.label"/></div> */}
                                <div>
                                    Se non hai un'app di autenticazione sul tuo telefono {/* <FormattedMessage id="MFA.firstStep.secondLabel"/> */}
                                <br></br>
                                    (Google Authenticator, Authy, Microsft Authenticator, etc...) {/* <FormattedMessage id="MFA.firstStep.thirdLabel"/>  */}
                                <br></br>
                                    Per favore installane uno dall'App Store o dal Play Store prima di procedere.{/* <FormattedMessage id="MFA.firstStep.fourthLabel"/> */}
                                </div>
                                <Button variant="contained" className="button-contained" onClick={ () => setStep(2) }>
                                    Prosegui
                                </Button>
                            </div>
                        : step === 2 ?
                            <div className='second-step'>
                                <div>Scansiona il codice QR con la tua app di autenticazione.</div> { /* <FormattedMessage id="MFA.secondStep.title"/> */ }
                                <div className={'qrCodeClass'} onClick={() => setShowQrCode(true)}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "180px", width: "100%", filter: showQrCode ? 'none' : 'blur(1.2rem)'}}
                                        value={stringQr}
                                        viewBox={`0 0 256 256`}
                                    />
                                    {!showQrCode && (<span className='showQrLabel cursor-pointer' onClick={()=>setShowQrCode(true)}>Premi per Mostrare</span>)} {/* <FormattedMessage id="MFA.showQR"/> */}
                                </div>
                                <div onClick={ () => setIsSecretToReveal(!isSecretToReveal)} className='secretCodeLabel cursor-pointer'>Se non riesci a scansionare il codice QR clicca qui per mostrare il codice segreto da inserire nella tua app di autenticazione.</div> {/* <FormattedMessage id="MFA.secondStep.secretCodeInfo"/> */}
                                {isSecretToReveal && (<div>{secretCode}</div>)}
                                <div>Se hai registrato correttamente il codice, procedi.</div> {/* <FormattedMessage id="MFA.secondStep.label"/> */}
                                <div className='second-step-buttons'>
                                    <Button variant="contained" className="button-contained" onClick={ () => {setStep(1); setShowQrCode(false)} }>
                                        Indietro {/* <FormattedMessage id="campaigns.go-back"/> */}
                                    </Button>
                                    <Button variant="contained" className="button-contained" disabled={!showQrCode} onClick={ () => setStep(3) }>
                                        Prosegui{/* <FormattedMessage id="MFA.next"/> */}
                                    </Button>
                                </div>
                            </div>
                        :
                            <div className='third-step'>
                                <div>Inserisci un codice MFA valido dalla tua applicazione per completare la configurazione</div> {/* <FormattedMessage id="MFA.thirdStep.title"/> */}
                                <form className='form-mfa-code' onSubmit={(event:React.FormEvent<HTMLFormElement>) =>submitTotp(event)}>
                                    <div className="input-container">
                                        <input 
                                            className    = "input"
                                            data-cy      = "totp" 
                                            data-testid  = "totp" 
                                            type         = "text" 
                                            name         = "totp" 
                                            maxLength    = {6}
                                            placeholder  = {"Codice"}
                                            spellCheck   = { false }
                                            autoComplete = "off"
                                            value        = {confirmTotpCode} 
                                            onChange     = { e => { if(inputOnlyNumbers(e.target.value))setConfirmToptCode(e.target.value); }}/>
                                    </div>
                                    <div className='third-step-buttons'>
                                        <Button variant="contained" className="button-contained" onClick={ () => setStep(2) }>
                                            Indietro
                                        </Button>
                                        <Button variant="contained" className="button-contained" disabled={confirmTotpCode.length!==6} type='submit'>
                                            Conferma
                                        </Button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ConfigMFA;