import React, {useState, useEffect, useContext} from 'react';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import config from '../../../../config';
import './ReportDn.css';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
    DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid-premium';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { formatNumbersIt, getFormatDate, getFormatDateFromDate } from '../../../../utils/CommonUtilities';
import { CheckBoxEmptyIcon, CheckBoxFilledIcon, EditIcon } from '../../../../assets/icons/icons';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function ReportDn(props:any) {
    
    const auth = useContext(AuthContext);
    const { userEmail, changePageName } = useContext(AppAuthGlobalVariables);
    const urlParams = useParams();

    const navigate = useNavigate();

    const [ loading  , setLoading  ] = useState(true);
    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [anno, setAnno] = useState(new Date().getFullYear().toString());
    const [soggettiFatturazione, setSoggettiFatturazione] = useState<any>([]);
    const [chosenSoggFatt, setChosenSoggFatt] = useState<any>(null);
    
    /* const ActionCell:any = (params:any) => {
        return !params.id ? null : <IconButton onClick={()=>{navigate(`/proposta/${params.row.KPROPOSTA}`)}}><EditIcon addClass='font-16'/></IconButton>
    } */

    const columns:GridColDef[] = [
        { field: 'DINTEST_FATT', headerClassName: 'header-cell soggettoFatturazioneDesc', headerName: "Soggetto Fatturazione", type: 'text', width: 300, editable: false},
        { field: 'DCLIENTE', headerClassName: 'header-cell clienteDesc', headerName: "Cliente", type: 'text', width: 300, editable: false},
        { field: 'DAGENZIA', headerClassName: 'header-cell agenziaDesc', headerName: "Agenzia", type: 'text', width: 300, editable: false},
        { field: 'KPROPOSTA', headerClassName: 'header-cell kProposta', headerName: "Id", type: 'text', width: 75, editable: false, align: 'center', headerAlign: 'center', disableColumnMenu: true},
        { field: 'DPROPOSTA', headerClassName: 'header-cell propostaDesc', headerName: "Proposta", type: 'text', width: 300, editable: false},
        { field: 'TOT_RICAVI', headerClassName: 'header-cell totaleProposta', headerName: "Totale proposta", description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica, Chiuse e con DN % valorizzata', type: 'text', width: 135, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
        { field: 'DN', headerClassName: 'header-cell dn', headerName: "Valore DN", description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica, Chiuse e con DN % valorizzata', type: 'text', width: 135, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
    ];

    useEffect(() => {
        changePageName?.(urlParams['*']);
        getSoggettiFatturazione();
    },[])
   
    useEffect(() => {
        async function init(){
            if(anno.length===4){
                await getReport();
            }
        }
        init();
    },[chosenSoggFatt, anno]);

    const getReport = async() => {
		
		try {
            let axiosParams = {
                url		: 'report-dn',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                    anno,
                    soggFattDesc: chosenSoggFatt ? chosenSoggFatt.label : '',
                    dnPercFrom: 0.01
				}
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data
				//const data = rows.map((el:any,index:number) => {el.id = index; el.hierarchy = el.CLIENTE_CHILD ? [el.CLIENTE_PARENT, el.CLIENTE_CHILD] : [el.CLIENTE_PARENT]; return el})
	        	//setRows([...data]);
                setRows([...rows]);
				setLoading(false);
            }
        } catch (e:any) {
			setLoading(false);
            if(e.response && e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response && e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
	}

    const getSoggettiFatturazione = async() => {
        try {
            let axiosParams = {
                url		: 'sogg-fatt',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {}
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data
                setSoggettiFatturazione([...rows]);
				setLoading(false);
            }
        } catch (e:any) {
            console.log(e)
			setLoading(false);
            if(e.response && e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response && e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    return (
        <div className='component-container report-dn'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <div className='report-wrapper'>
                    <div className='filters-section'>
                        <TextField 
                            id="anno" 
                            label="Anno" 
                            variant="standard"
                            value={anno}
                            onChange={(event) => setAnno(event.target.value)} 
                            inputProps={{maxLength: 4}}
                            error={anno.length!==4}
                            sx={{maxWidth:'80px'}}
                        />

                        <Autocomplete
                            multiple={false}
                            id="autocomplete-soggetto-fatturazione"
                            options={soggettiFatturazione}
                            value={chosenSoggFatt}
                            clearOnBlur={false}
                            onChange={(event, newValue) => {
                                setChosenSoggFatt(newValue);
                            }}
                            groupBy = { (option: any) => {return option.TYPE}}
                            renderInput={(params) => <TextField {...params} label="Soggetto di Fatturazione" className='sogg-fatt-field' variant='standard' style={{minWidth: '300px'}}/>}
                        />
                    </div>
                    <DataGridPremium
                        className='dataGrid'
                        slots={{
                            noRowsOverlay: NoRowsDataGridComponent,
                            toolbar: CustomToolbar
                        }}
                        initialState={{ pinnedColumns: { right: ['FLAG_CONFERMA_ATT', 'FLAG_FATTURATO'] } }}
                        rows={rows}
                        columns={columns}
                        rowHeight={30}
                        hideFooter={true}
                        isCellEditable={(params) => params.id!==0}
                        disableColumnMenu={false}
                        disableColumnReorder={true}
                        disableAggregation={true}
                        disableColumnFilter={true}
						disableRowGrouping={true}
                        getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
                        pinnedRows={{bottom: [], top: [rows.length > 0 ? rows.find((row:any) => row.id===0) : {id:0}]}}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReportDn;