import React, {useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../../../config';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { UrlParamsObject, UserParams } from '../../../../utils/ConfigInterfaces';
import { IconButton } from '@mui/material';
import { ArrowLeft, TrashIcon, SaveIcon, ActiveIcon, DisabledIcon } from '../../../../assets/icons/icons';
import './Utenti.css';
import { getFormatDateTime } from '../../../../utils/CommonUtilities';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';

function UserDetail(props:any) {

    const { setPageName } = useContext(AppAuthGlobalVariables);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const urlParams:UrlParamsObject = useParams();
    const [kUser, setKUser] = useState(urlParams?.id)
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [flagAdmin, setFlagAdmin] = useState(false);
    const [flagAttivo, setFlagAttivo] = useState(false);

    const [flagAmministrazione, setFlagAmministrazione] = useState(false);
    const [flagReferenteCommerciale, setFlagReferenteCommerciale] = useState(false);
    const [flagTalentManager, setFlagTalentManager] = useState(false);

    const [dataIns, setDataIns] = useState('');
    const [dataMod, setDataMod] = useState('');
    const [dataCanc, setDataCanc] = useState('');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(kUser!=='new'){
            getUtenti();
        }else{
            setLoading(false);
            setPageName?.('Nuovo Utente');
        }
    },[])

    const goToList = () => {
        navigate('/utenti');
    }

    const getUtenti = async () => {
		try {    
			let axiosParams = {
				url		: 'users',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params  : {
                    kUser
                }
			}
			let response = await axios(axiosParams);

            if (response.status === 200){
                let data = response.data[0];
                
                setCognome(data.COGNOME);
                setNome(data.NOME);
                setEmail(data.EMAIL_ADDRESS);
                setFlagAdmin(data.FLAG_ADMIN === 'Y' ? true : false);
                setFlagAttivo(!data.DATA_CANC ? true : false);

                setFlagAmministrazione(data.FLAG_AMMINISTRAZIONE === 'Y' ? true : false);
                setFlagTalentManager(data.FLAG_TALENT_MANAGER === 'Y' ? true : false);
                setFlagReferenteCommerciale(data.FLAG_REF_COMM === 'Y' ? true : false);

                setDataIns(getFormatDateTime(data.DATA_INSERIMENTO));
                setDataMod(getFormatDateTime(data.DATA_ULTIMA_MODIFICA));
                setDataCanc(getFormatDateTime(data.DATA_CANCELLAZIONE));
                setPageName?.(data.NOME + ' ' + data.COGNOME);
            }
            setLoading(false);
		} catch (e:any) {
            setLoading(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
 	}

    const submitProcedure = async() => {
        setLoading(true)
        try{
            let response = undefined;

            const userData:UserParams = {
                cognome,
                nome,
                email,
                flagAdmin: flagAdmin ? 'Y' : 'N',
                flagAmministrazione: flagAmministrazione ? 'Y' : 'N',
                flagTalentManager: flagTalentManager ? 'Y' : 'N',
                flagReferenteCommerciale: flagReferenteCommerciale ? 'Y' : 'N'
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...userData
                }
            }

            if(kUser === 'new'){
                axiosParams.url = 'user-ins';
                response = await axios(axiosParams);
            }else{
                axiosParams.url = 'user-mod';
                axiosParams.data.kUser = kUser;
                response = await axios(axiosParams);
            }

            if(response.status === 200){
                NotificationManager.success('Procedura completata', '', 5000);
                goToList();
                setLoading(false)
            }
        }catch(e:any){
            setLoading(false);
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    /* const toggleUser = async () => {
        try{
            let response = undefined;

            const agentData = {
                kAgenzia,
                undo: dataCanc ? true : false
            }

            let axiosParams = {
                url: 'agenzia-del',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...agentData
                }
            }
            
            response = await axios(axiosParams);
           
            if(response.status === 200){
                NotificationManager.success(dataCanc ? 'Agenzia attivata' : 'Agenzia disattivata')
                goToList();
            }
        }catch(e){
            NotificationManager.error(JSON.stringify(e), 'Error', 15000)
        }
    } */

    return (
        <div className='component-container dettaglio-utenti'>
            {loading && (<CircleProgress/>)}
            <div className='component-card'>
                <div className='actions-wrapper'>
                    <IconButton title='Indietro' onClick={goToList}><ArrowLeft/></IconButton>
                    <div className='wrapper-actions'>
                        {/* {kUser!=='new' && (<IconButton title={dataCanc ? 'Attiva' : 'Disabilita'} onClick={toggleUser}>{dataCanc ? <ActiveIcon/> : <DisabledIcon/>}</IconButton>)} */}
                        <IconButton title='salva' onClick={submitProcedure} disabled={nome === '' || cognome === '' || email === '' }><SaveIcon/></IconButton>
                    </div>
                </div>
                <div className='infos-wrapper'>
                    <div className='flex-row'>
                        <TextField 
                            id="nome" 
                            label="Nome" 
                            variant="standard"
                            value={nome}
                            onChange={(event) => setNome(event.target.value)}
                            inputProps={{maxLength: 100}}
                        />

                        <TextField 
                            id="cognome" 
                            label="Cognome" 
                            variant="standard"
                            value={cognome}
                            onChange={(event) => setCognome(event.target.value)} 
                            inputProps={{maxLength: 100}}
                        />

                    </div>
                    <div className='flex-row'>

                        <TextField 
                            id="email" 
                            label="Email" 
                            variant="standard"
                            value={email}
                            disabled={kUser!=='new'}
                            onChange={(event) => setEmail(event.target.value)} 
                            inputProps={{maxLength: 254}}
                        />

                    </div>
                    <div className='flex-row'>
                        <FormGroup className='checkboxes-wrapper'>
                            <FormControlLabel control={<Checkbox checked={flagAdmin} onChange={(event) => setFlagAdmin(event.target.checked)}/>} label="Admin" />
                            <FormControlLabel control={<Checkbox checked={!!flagAmministrazione} onChange={(event) => setFlagAmministrazione(event.target.checked)}/>} label="Amministrazione" />
                            <FormControlLabel control={<Checkbox checked={!!flagTalentManager} onChange={(event) => {setFlagTalentManager(event.target.checked)}} />} label="Talent Manager" />
                            <FormControlLabel control={<Checkbox checked={!!flagReferenteCommerciale} onChange={(event) => {setFlagReferenteCommerciale(event.target.checked)}} />} label="Referente Commerciale" />
                        </FormGroup>
                    </div>

                    {kUser!=='new'&&(
                        <div className='flex-row dates-section'>
                            <TextField 
                                id="dataIns" 
                                label="Data Inserimento" 
                                variant="standard"
                                value={dataIns}
                                disabled={true}
                            />
                            <TextField 
                                id="dataMod" 
                                label="Data Ultima Modifica" 
                                variant="standard"
                                value={dataMod}
                                disabled={true}
                            />
                            <TextField 
                                id="dataCanc" 
                                label="Data Cancellazione" 
                                variant="standard"
                                value={dataCanc}
                                disabled={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserDetail