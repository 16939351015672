import React from 'react'

function Page403() {
	return (
		<div className='component-container dashboard'>
			<div className='component-card'>
				Page403
			</div>
		</div>
	)
}

export default Page403