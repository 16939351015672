import React,{useContext, useEffect} from 'react';
import { AppAuthGlobalVariables } from '../../../contexts/AppAuthContext';

function Page404() {
	const { setPageName } = useContext(AppAuthGlobalVariables);

	useEffect(() => {
		setPageName?.('');
	},[])

	return (
		<div className='component-container dashboard'>
			<div className='component-card'>
				Pagina inesistente
			</div>
		</div>
	)
}

export default Page404