import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import config from '../../../../config';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';

function Impersonate(props:any) {

    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const { setUserEmail, setPageName } = useContext(AppAuthGlobalVariables);
    const [ loading, setLoading ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const [ chosenUser, setChosenUser ] = useState<any>('');

    useEffect(() => {
        async function init(){
            setPageName?.('Impersona');
            await getUtenti();
        }
        init();
    },[]);

    const getUtenti = async () => {
		try {
			let axiosParams = {
				url		: 'users',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
                let removedAdmins = response.data.filter((utente:any) => utente.FLAG_ADMIN === 'N');
				setUsers(removedAdmins);
			}
			setLoading(false);
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
        setLoading(false);
 	}

    const impersonate = () => {
        let utente:any = users.find((user:any) => user.id === chosenUser);
        props.setAdminUser(false);
        props.setIsUserInImpersonate(true);
        setUserEmail?.(utente.EMAIL_ADDRESS);
        localStorage.setItem('impersonateKUser', chosenUser);
        localStorage.setItem('admin', utente.FLAG_ADMIN);
        localStorage.setItem('talent_manager', utente.FLAG_TALENT_MANAGER);
        localStorage.setItem('referente_commerciale', utente.FLAG_REF_COMM);
        localStorage.setItem('amministrazione', utente.FLAG_AMMINISTRAZIONE);
        navigate('/dashboard')
    }

    return (
        <div className='component-container impersonate'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <div className='flexed' style={{gap: 10, alignItems: 'flex-end'}}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
                        <InputLabel id="users">Utente</InputLabel>
                        <Select
                            labelId="users"
                            id="users"
                            value={chosenUser.id}
                            onChange={(event ) => setChosenUser(event.target.value)}
                            label="Utente"
                        >
                            <MenuItem value=""></MenuItem>
                            {users.map((user:any) => <MenuItem value={user.id}>{user.NOME} {user.COGNOME}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button onClick={impersonate}>Conferma</Button>
                </div>
            </div>
        </div>
    )
}

export default Impersonate