import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { NotificationManager } from 'react-notifications';
import config from '../../../../../config';
import axios from 'axios';
import { AuthContext } from '../../../../../contexts/authContext';
import NoRowsDataGridComponent from '../../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { IconButton } from '@mui/material';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel
} from '@mui/x-data-grid-premium';
import { DownLoadIcon, TrashIcon } from '../../../../../assets/icons/icons.tsx';
import { AppAuthGlobalVariables } from '../../../../../contexts/AppAuthContext.tsx';
import { getDateFromBucketStringDate } from '../../../../../utils/CommonUtilities.ts';

const baseStyle = {
    //flex: 1,
    width: '250px',
    height: '400px',
    marginLeft: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //padding: '50px',
    borderWidth: 3,
    borderRadius: 5,
    borderColor: '#aaaaaa',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const ActionCell:any = (params:any, reloadRows:any, setLoading:any) => {

	const auth = useContext(AuthContext);

	const download = async() => {
		try{
			setLoading(true);
			fetch(config.ATTACHMENTS_URL+'/'+params.row.Key, {
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
				  	"Content-Type": "application/json",
				  	authorization: `${auth.sessionInfo?.accessToken}`
				},
			  }).then(resp => resp.blob())
			  	.then(blob => {
						const url = window.URL.createObjectURL(blob);
						const a = document.createElement("a");
						a.style.display = "none";
						a.href = url;
						a.download = params.row.fileName;
						document.body.appendChild(a);
						a.click();
						window.URL.revokeObjectURL(url);
					})
			  	.catch((error) => console.error(error));
		}catch(e:any){
			NotificationManager.error('Errore nel Download del file', '', 10000);
		}finally{
			reloadRows();
		}
	}

	const deleteFile = async() => {
		setLoading(true);
		try{
			let axiosParams = {
				url		: 'proposta-del-allegato',
				method	: 'post',
				baseURL	: config.API_URL,
				headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
				data    : { filePath: params.row.Key }
			}

			let response = await axios(axiosParams);

			if(response.status===200){
				NotificationManager.success('File Eliminato', '', 5000);
			}
		}catch(e){
			NotificationManager.error('Eliminazione file non riuscita', '', 10000);
		}finally{
			reloadRows();
		}
	}

    return (
		<div>
			<IconButton onClick={()=>{download()}}><DownLoadIcon addClass='font-16'/></IconButton>
			<IconButton onClick={()=>{deleteFile()}}><TrashIcon addClass='font-16'/></IconButton>
		</div>
	)
}

function Allegati(props:any) {

	const auth = useContext(AuthContext);
	const appContext = useContext(AppAuthGlobalVariables);
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		async function init(){
			await getPropostaFiles();
		}
		init();
	},[])

	const columns:GridColDef[] = [
		{ field: 'fileName', headerClassName: 'header-cell fileName', headerName: "File", type: 'text', width: 400, editable: false},
		{ field: 'LastModified', headerClassName: 'header-cell dataUpload', headerName: "Data Upload", type: 'text', width: 100, editable: false, align: 'center', headerAlign: 'center', valueFormatter:(params)=>getDateFromBucketStringDate(params.value)},
		{ field: 'userUpload', headerClassName: 'header-cell userInserimento', headerName: "Caricato Da", type: 'text', width: 200, editable: false},
		{ field: 'Key', headerClassName: 'header-cell bucketFilePath', headerName: 'BucketFileName', type: 'text', width: 50, editable: false },
		{ field: 'ACTION', headerClassName: 'header-cell action', headerName: "", type: 'text', width: 60, editable: false, sortable: false, headerAlign: 'center', align: 'center',
			renderCell: (params:any) => ActionCell(params, getPropostaFiles, props.setLoading),
			disableColumnMenu: true}
	]

	const getPropostaFiles = async() => {
		try{
			let axiosParams:any = {
                url: 'proposta-allegati',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
					kProposta: props.kProposta
				}
            }

			let response = await axios(axiosParams);

			if(response.status===200){
				let files = response.data.Contents

				if(!response.data.Contents){
					props.setLoading(false);
					setRows([])
					return;
				}
				for (let index = 0; index < files.length; index++) {
					const element = files[index];

					element.id = index;
					let keySplit = element.Key.split('/')
					let keySplitLength = element.Key.split('/').length
					let userLoader = keySplit[keySplitLength-1].split('¶')[0]
					let originalFileName = keySplit[keySplitLength-1].split('¶')[1]

					element.userUpload = userLoader;
					element.fileName = originalFileName;
					files[index] = element;
				}
				setRows(files);
				props.setLoading(false);
			}
		}catch(e){
			NotificationManager.error('Errore nell\'ottenere la lista dei file', '', 10000);
		}
	}

	// *** dropzone ***

	const onDrop = useCallback(async(acceptedFiles:any) => {
		let successFiles = [];
		let failedFiles = [];

		props.setLoading(true);
		for (let index = 0; index < acceptedFiles.length; index++) {
			try {
				let fileName = appContext.userEmail+'¶'+acceptedFiles[index].name

				
				//QUESTO FUNZIONA PER LE LAMBDA MA NON PER EC2
				//------------
				/* const result = await fetch(
					`${config.API_URL}/proposta-up-allegato?key=proposte/${props.kProposta}/${fileName}`, {
					method: 'POST',
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${auth.sessionInfo?.accessToken}`
					},
					body: acceptedFiles[index]
				}); */
				//------------
				const form = new FormData();
				form.append('file', acceptedFiles[index]);
				const result = await fetch(
					`${config.API_URL}/proposta-up-allegato?key=proposte/${props.kProposta}/${fileName}`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${auth.sessionInfo?.accessToken}`
					},
					body: form
				});
				//
				const message = await result.json()/*  as string */;
			
				if(message?.$metadata.httpStatusCode === 200){
					successFiles.push('a');
				}
			/* let axiosParams = {
				url:  `proposta-up-allegato?key=proposte/${props.kProposta}/${fileName}`,
				method: 'post',
				baseURL: config.API_URL,
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${auth.sessionInfo?.accessToken}`
				  },
				body: form
			}
			let response = await axios(axiosParams);

			console.log(response) */
			} catch (err) {
				failedFiles.push('a');
			}
		}

		/* for (let index = 0; index < acceptedFiles.length; index++) {
			console.log(acceptedFiles[index])
			let response:any = '';
			let fileName = appContext.userEmail+'¶'+acceptedFiles[index].name//uuidv4();
		
			//path per il file che deve essere caricato 
			let key = `proposte/${props.kProposta}/${fileName}`;
			try{
			//upload del file nella cartella original
			let axiosParams:any = {
                url: 'request-signed-url',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
					actionRequest: 'carica',
					key
				}
            }

			response = await axios(axiosParams);

			console.log(response)

			if(response?.status === 200){

				let file = acceptedFiles[index]
				
				axiosParams = {
					url:  response.data.url,
					method: 'put',
					headers: {
						"Content-Type": "multipart/form-data"
					  },
					data: file
				}
				response = await axios(axiosParams);
				console.log(response)

				if(response?.status === 200){
					successFiles.push('a');
				}

			}

			}catch(error){
				failedFiles.push('a');
				console.log(error);
				
				setLoadingUpload(false);
			}
			
		}
*/
		if(failedFiles.length===0){
			NotificationManager.success(successFiles.length===1?'File Caricato':`${successFiles.length}/${acceptedFiles.length} Files Caricati`, '', 3000);
		}else{
			NotificationManager.error(failedFiles.length===1?'File non Caricato':`${successFiles.length}/${acceptedFiles.length} Files Caricati`, '', 3000);
		} 

		getPropostaFiles();
		setLoadingUpload(false);
	}, []);

	const {
        /* acceptedFiles, */
        getRootProps,
        getInputProps,
        open,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {/* 'video/mp4':['.mp4'] */},  multiple: true/* , maxFiles: 1, maxSize: 31457280 */});

	const dropZonestyle:any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

  	return (
		<div className='allegati-container flexed full-height'>
			<DataGridPremium
				className='dataGrid'
				slots={{
					noRowsOverlay: NoRowsDataGridComponent,
				}}
				initialState={{ pinnedColumns: { left: ['ACTION'], right: [] } }}
				rows={rows}
				columns={columns}
				rowHeight={30}
				hideFooter={true}
				isCellEditable={(params) => params.id!==0}
				disableColumnMenu={false}
				disableColumnReorder={false}
				disableAggregation={true}
				columnVisibilityModel={{Key: false}}
			/>
			<div className="dropBox" {...getRootProps({style: dropZonestyle})}>
				<input {...getInputProps()} />
				<p style={{textAlign: "center"}}>Clicca per caricare uno o più file</p>
				<p style={{textAlign: "center"}}>Oppure trascina qui</p>
			</div>
		</div>
	)
}

export default Allegati